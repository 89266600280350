import React from 'react'
import './new-shadow-box.scss'

export default function NewShadowBox({ visible, modal, children, className }) {
	return (
		<div
			className={className ? `new-shadow-box ${className}` : 'new-shadow-box'}
		>
			<div
				className={visible ? `translucent-bg show` : `translucent-bg hide`}
			/>
			{visible ? <div className={`modal`}>{modal}</div> : null}
			{children}
		</div>
	)
}
