import React, { forwardRef, useRef, useEffect } from 'react'
import './indeterminate-checkbox.scss'

export const IndeterminateCheckbox = forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = useRef()
		const resolvedRef = ref || defaultRef

		useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate
		}, [resolvedRef, indeterminate])

		return (
			<>
				<input
					className='checkbox'
					type='checkbox'
					ref={resolvedRef}
					{...rest}
				/>
			</>
		)
	}
)
