import { useState, useEffect } from 'react'

export default function useCanSubmitForm(formObj, additionalCondition = true) {
	const [canSubmit, setCanSubmit] = useState(false)

	useEffect(() => {
		const isFormComplete = () => {
			let result = !Object.values(formObj).includes('')
			return result
		}
		if (isFormComplete() && additionalCondition) {
			setCanSubmit(true)
		} else {
			setCanSubmit(false)
		}
	}, [formObj, additionalCondition])

	return canSubmit
}
