import React from 'react'
import InputMask from 'react-input-mask'
import './textinput.scss'

export default function ({ mask, className, ...otherProps }) {
	return (
		<InputMask
			className={className ? `text-input ${className}` : 'text-input'}
			mask={mask}
			{...otherProps}
		/>
	)
}

//https://github.com/sanniassin/react-input-mask
