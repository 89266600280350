const baseFontSize = 10

const globals = {
	'font-color-base': '#aaaaaa',

	// theme colors
	red: '#e94144',
	orange: '#f2a34e',
	green: '#2ddb6d',
	blue: '#029be7',
	'blue-dark': '#0063c3',
	purple: '#a96aff',
	'purple-dark': '#7143af',

	// dark-theme grayscale
	black: '#0b0b0b',
	'gray-darkest': '#16171b',
	'gray-darker': '#1d1e22',
	'gray-dark': '#212226',
	'gray-light': '#26272b',
	'gray-lighter': '#34363c',
	'gray-lightest': '#7b7f88',
	white: '#e9e9e9',
	//Does not exist is DS
	gray: '#39393d',
	//

	// light-theme colors
	'lt-white': '#FFFFFF',
	'lt-off-white': '#fafdff',
	//
	'lt-gray-lightest': '#f0f4ff',
	'lt-gray-lighter': '#dadcec',
	'lt-gray-light': '#b6b8ca',
	'lt-gray-dark': '#33343d',
	'lt-gray-darkest': '#25252a',
	// does not exist in DS
	'lt-gray-darker': '#858EA6',
	//

	// gradients
	'admin-gradient': 'linear-gradient(90deg, #220707 0%, #492f13 100%)',
	'scorecard-gradient': 'linear-gradient(-90deg, #00412e 0%, #042544 100%)',
	'pipeline-gradient': 'linear-gradient(90deg, #210034 0%, #46003b 100%)',
	'reporting-gradient': 'linear-gradient(90deg, #061d0f 0%, #092c19 100%)',
	'valuation-gradient': 'linear-gradient(-90deg, #310d0d 0%, #0c081f 100%)',
	'system-admin-gradient':
		'linear-gradient(90deg, rgba(4,2,2,1) 7%, rgba(92,6,6,1) 83%)',

	// typography
	'default-font': `'Nunito Sans', sans-serif`,
	'number-font': `'Roboto Mono', monospace`,
	'font-size-xxl': rem(84.5),
	'font-size-xl': rem(42.25),
	'font-size-largest': rem(28),
	'font-size-larger': rem(21.5),
	'font-size-large': rem(18.5),
	'font-size-default': rem(16),
	'fonst-size-smallish': rem(15),
	'font-size-small': rem(14),
	'font-size-smaller': rem(12),
	'font-size-smallest': rem(10),
	'base-font-size': baseFontSize,
	// 'font-size-xl': '3.5vw',
	// 'font-size-largest': '3vw',
	// 'font-size-larger': '2.5vw',
	// 'font-size-large': '2vw',
	// 'font-size-default': '1.5vw',
	// 'font-size-small': '1.25vw',

	// dimensions
	'padding-default': '1rem',
	'header-height': '62px',
	'sidebar-width': '62px',
	'sidebar-expander-width': '15px',
	'expanded-sidebar-width': '275px',
	'data-filter-height': '64px',
	'content-height': `calc(100vh - var(--header-height))`,
	'content-width': `calc(100vw - var(--sidebar-width) - var(--sidebar-expander-width) - 20px)`,
	'loading-animation-height': '64px',

	// special effects
	'white-shadow': 'drop-shadow(2px 4px 6px #E7EBF4)',
}

function rem(n) {
	return n / baseFontSize + 'rem'
}

export default globals
