import React from 'react'
import './screen-alert-container.scss'

export default function ScreenAlertContainer({ children, ...otherProps }) {
	return (
		<div id={'screen-alert-container'} {...otherProps}>
			{children}
		</div>
	)
}
