import React, { useEffect } from 'react'
import AuthLayout from './AuthLayout'
import LoginForm from './LoginForm'
import { Router } from '@reach/router'
import appInsights from '../AppInsightsConfig'

export default function LoginRoutes() {
	useEffect(() => {
		appInsights.trackPageView({ name: 'Login Screen' })
	}, [])

	return (
		<AuthLayout>
			<Router>
				<LoginForm path='/' default />
				<LoginForm path='portal/:enterpriseId' />
				<LoginForm path='portal' />
			</Router>
		</AuthLayout>
	)
}
