import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LogoBox, Alert, Button, TextInput } from '../exports'

EmailBox.propTypes = {
	alertMessage: PropTypes.string,
	title: PropTypes.string,
	canSubmit: PropTypes.bool,
	// Get error from React saying these are undefined if I make them required. They are clearly working, however...
	domainCheck: PropTypes.func,
	onSubmit: PropTypes.func,
}

export default function EmailBox({
	onSubmit,
	title,
	alertMessage,
	emailType,
	domainCheck,
	footer,
	canSubmit,
	resetAlert,
}) {
	const [emailInput, setEmailInput] = useState('')
	const [isEmailValid, setIsEmailValid] = useState(true)

	useEffect(() => {
		resetAlert()
	}, [])

	const handleOnChange = e => {
		let email = e.target.value
		setEmailInput(email)
		// removes alert messages from global alert component
		resetAlert()
	}

	const handleOnSubmit = async e => {
		e.preventDefault()
		if (domainCheck) {
			domainCheck(emailInput)
		} else {
			onSubmit(emailInput)
		}
	}

	if (domainCheck && canSubmit) {
		onSubmit(emailInput)
		return null
	} else {
		return (
			<LogoBox footer={footer}>
				<Alert
					hidden={alertMessage ? false : true}
					dismissible={true}
					onCloseAlert={resetAlert}
					data-testid='alert-msg1'
				>
					{alertMessage}
				</Alert>
				<Alert
					type='danger'
					hidden={isEmailValid ? true : false}
					dismissible={true}
					onCloseAlert={() => setIsEmailValid(true)}
					data-testid='alert-msg2'
				>
					Please enter a valid email address.
				</Alert>
				<form className='form' onSubmit={handleOnSubmit}>
					<h3>{title}</h3>
					<TextInput
						className=''
						placeholder='Company Email'
						type='email'
						name='email'
						value={emailInput}
						onChange={handleOnChange}
						data-testid='email-check_input'
						required
					/>
					<div className='btn-container'>
						<Button
							type='submit'
							color='bones'
							data-testid='email-check_submit'
						>
							Submit
						</Button>
					</div>
				</form>
			</LogoBox>
		)
	}
}
