import React from 'react'
import { LogoBox } from '../exports'
import './result-box.scss'

export default function ResultBox({
	heading,
	body,
	boxType,
	footer,
	testId,
	...otherProps
}) {
	const renderIcon = () => {
		switch (boxType) {
			case 'success':
				return (
					<div className='success-icon'>
						<i className='icon-checkmark' aria-hidden='true'></i>
					</div>
				)
			case 'failure':
				return (
					<div className='failure-icon'>
						<div>!</div>
						{/* <i className="icon-cross" aria-hidden="true"></i> */}
					</div>
				)
			default:
				return (
					<div className='error-icon'>
						<i className='icon-question-circle-regular' aria-hidden='true'></i>
					</div>
				)
		}
	}
	return (
		<div data-testid={testId}>
			<LogoBox className='result-box' footer={footer} {...otherProps}>
				{/* <div data-testid={testId}></div> */}
				<h3 className='heading'>{heading}</h3>
				{/* <div className='icon-container'> */}
				{renderIcon()}
				{/* </div> */}
				<div className='body'>{body}</div>
			</LogoBox>
		</div>
	)
}
