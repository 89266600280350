import React from 'react'
import { Button } from '../exports'
import './delete-confirmation.scss'

export default function DeleteConfirmation({ onCancel, onDelete, itemToDelete, headerText }) {

  return (
    		<div className='delete-confirmation'>
          <div className='delete-confirmation-header'>
            <h4 className='header'>
             {headerText}
            </h4>
            <Button className='exit-btn' onClick={onCancel}>
				      X
			      </Button>
          </div>
    			<div className='delete-confirmation-prompt'>
            {`Are you sure you want to delete ${itemToDelete}? You cannnot undo this once it has been deleted.`}
    			</div>
    			<div className='delete-confirmation-buttons'>
    				<Button className='cancel' onClick={onCancel}>
    					Cancel
    				</Button>
    				<Button className='confirm' onClick={onDelete}>
    					Confirm
    				</Button>
    			</div>
    		</div>
    	)
}

DeleteConfirmation.defaultProps = {
  itemToDelete: 'this item',
  headerText: 'Delete Confirmation:'
}