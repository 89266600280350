import React, { useState } from 'react'
import { appendClassName } from '../utils'
import { ArrowIndicator } from '../exports'
import './accordion.scss'

export default function Accordion({
	className,
	children,
	header,
	noCollapsedTiers,
	noBorder,
	...otherProps
}) {
	const [extended, setExtended] = useState(true)

	const renderCollapsedState = () => {
		if (extended) {
			return <div className='extended-accordion'>{children}</div>
		} else if (noCollapsedTiers) {
			return null
		} else {
			return (
				<div
					className='collapsed-accordion-tiers'
					data-testid='collapsed-accoridon-tiers'
				>
					<div className='tier-one' />
					<div className='tier-two' />
				</div>
			)
		}
	}

	return (
		<div
			className={appendClassName('accordion-box', className)}
			{...otherProps}
		>
			<div
				className={appendClassName(
					'accordion-header',
					noBorder ? 'no-border' : null
				)}
			>
				{header}
				<ArrowIndicator
					className='dropdown-btn'
					size='large'
					direction={extended ? 'up' : 'down'}
					onClick={() => setExtended(!extended)}
					aria-label={extended ? 'close' : 'open'}
				/>
			</div>
			{renderCollapsedState()}
		</div>
	)
}
