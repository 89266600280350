import React, { useState } from 'react'
import {
	DropdownMenu,
	Toggle,
	PasswordBox,
	ResultBox,
	Button,
} from '../shared/exports'
import { useAuth, useAlerts } from '../authentication/AuthHooks'
import { useUpdateTheme } from '../ThemeProvider'
import AppVersion from 'AppVersion'
import qlogo from '../assets/images/logo_black_bg.svg'
import './q-header.scss'

export default function QHeader() {
	const { isAuthenticated, logout, changePassword } = useAuth()
	const { alertObj, setAlertObj, displayError } = useAlerts()
	const { updateTheme, theme } = useUpdateTheme()
	const [showPasswordModal, setShowPasswordModal] = useState(false)
	const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false)

	const onPasswordChange = async formInputs => {
		let body = {
			oldPassword: formInputs.oldPassword,
			newPassword: formInputs.password,
		}
		let success = await changePassword(body)
		if (success) {
			setPasswordChangeSuccess(true)
		}
	}

	const onPasswordModalClose = () => {
		setShowPasswordModal(false)
		setPasswordChangeSuccess(false)
	}

	const userOptions = [
		{
			label: 'Logout',
			value: 'logout',
			icon: 'icon-logout',
			onClick: logout,
		},
		{
			label: 'Change Password',
			value: 'changePassword',
			icon: 'icon-password',
			onClick: () => setShowPasswordModal(true),
		},
	]

	return (
		<div className={isAuthenticated ? 'app-header' : 'app-header logged-out'}>
			<div className='header-left'></div>
			<div className='header-right'>
				<img
					className='logo'
					src={qlogo}
					alt='Quantalytix Logo'
					height='25px'
				/>
				<div className='logged-in-user'>
					{isAuthenticated && (
						<div className='header-container'>
							<Toggle
								selected={theme === 'dark'}
								handleChange={() =>
									updateTheme(theme === 'dark' ? 'light' : 'dark')
								}
								data-testid='theme_toggle'
							/>
						</div>
					)}
					<div className='header-container'>
						<i className='icon-alert'></i>
					</div>
					<DropdownMenu
						className='user-options'
						buttonDisplay={<i className='icon-user' />}
						width='65px'
						noHoverEffect={true}
						keepDarkTheme={true}
						customColors={{ background: 'var(--header-bg)' }}
						customStyles={{ menu: { width: 'fit-content', right: 0 } }}
						options={userOptions}
					/>
					{showPasswordModal ? (
						<div
							className='change-password-modal'
							data-testid='change-password-modal'
						>
							{passwordChangeSuccess ? (
								<ResultBox
									footer={
										<Button color='bones' onClick={onPasswordModalClose}>
											Close
										</Button>
									}
									boxType='success'
									heading='Password Changed!'
									body='You will be able to use your new password the next time you log in.'
								/>
							) : (
								<PasswordBox
									footer={
										<Button
											color='bones'
											onClick={() => setShowPasswordModal(false)}
										>
											Cancel
										</Button>
									}
									title='Change Password'
									onSubmit={onPasswordChange}
									alertMessage={displayError}
									resetAlert={() =>
										setAlertObj({ ...alertObj, displayError: '' })
									}
									requestPrevious={true}
								/>
							)}
						</div>
					) : null}
				</div>
			</div>
			{isAuthenticated ? null : <AppVersion />}
		</div>
	)
}
