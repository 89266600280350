import React from 'react'
import './data-set.scss'

export default function DataSet({ label, name, children, ...otherProps }) {
	return (
		<div className='data-set' {...otherProps}>
			<label htmlFor={name}>{label}</label>
			{children}
		</div>
	)
}
