import { globals } from '../exports'

const themes = {
	dark: {
		primary: globals.blue,
		'primary-dark': globals['blue-dark'],
		secondary: globals.purple,
		'secondary-dark': globals['purple-dark'],
		'bg-1': globals.black,
		'bg-2': globals['gray-darker'],
		'bg-3': globals['white'],
		'header-bg': globals['gray-darkest'],
		'header-btn-border-color': globals['gray-lighter'],
		'sidebar-bg': globals['gray-darkest'],
		'sidebar-menu': globals['gray-darker'],
		'navigation-text': globals['white'],
		'navigation-text-muted': globals['gray-lightest'],
		text: globals['white'],
		'text-muted': globals['gray-lightest'],
		'panel-border': `1px solid ${globals['gray-darkest']}`,
		'header-panel-bg': globals['gray-dark'],
		'header-panel-hover': globals['gray-light'],
		'header-panel-text': globals['white'],
		'panel-body': globals['gray-darkest'],
		'footer-panel-bg': globals['gray-dark'],
		'data-table-text': globals['font-color-base'],
		'data-table-header-text': globals['white'],
		'data-table-bg-dark': globals['gray-darkest'],
		'data-table-bg-light': globals['gray-darker'],
		'data-table-accent': globals.black,
		'data-table-border': globals['gray-darker'],
		'data-table-filter': globals['gray-darker'],
		'gray-hover': globals['gray-light'],
		'alert-bg': globals.blue,
		'button-bg': globals['gray-dark'],
		'button-bg-2': globals['gray-darker'],
		'button-bg-3': globals['black'],
		'button-disabled': globals['gray-lighter'],
		'button-text': globals['white'],
		'border-color': globals['gray-light'],
		danger: globals.red,
		success: globals.green,
		'toggle-bg': globals['gray-light'],
		'expander-bg': globals.black,
		'expander-arrow': globals['white'],
		'expander-circle': globals['white'],
		'expander-bg-hover': globals.blue,
		'expander-arrow-hover': globals['white'],
		'expander-circle-hover': globals.blue,
		'icon-color': globals['white'],
		scrollbar: globals['gray-light'],
		'scrollbar-hover': globals['gray-lighter'],
		'box-shadow-color': 'rgba(0, 0, 0, 0.4)',
		'loading-color': 'rgba(29, 29, 29, 0.8)',
	},
	light: {
		primary: globals.blue,
		'primary-dark': globals['blue-dark'],
		secondary: globals.purple,
		'secondary-dark': globals['purple-dark'],
		'bg-1': globals['lt-off-white'],
		'bg-2': globals['lt-off-white'],
		'bg-3': globals['lt-light'],
		'header-bg': globals['lt-gray-darkest'],
		'header-btn-border-color': globals['lt-gray-dark'],
		'sidebar-bg': globals['lt-gray-darkest'],
		'sidebar-menu': globals['lt-gray-darkest'],
		'navigation-text': globals['lt-white'],
		'navigation-text-muted': globals['lt-gray-light'],
		text: globals['lt-gray-darkest'],
		'text-muted': globals['lt-gray-dark'],
		'data-table-text': globals['lt-gray-darkest'],
		'panel-border': `1px solid ${globals['lt-gray-lightest']}`,
		'header-panel-bg': globals['lt-gray-darkest'],
		'header-panel-text': globals['white'],
		'header-panel-hover': globals['lt-gray-dark'],
		'panel-body': globals['lt-off-white'],
		'footer-panel-bg': globals['lt-off-white'],
		'data-table-header-text': globals['lt-gray-darkest'],
		'data-table-bg-dark': globals['lt-off-white'],
		'data-table-bg-light': globals['lt-off-white'],
		'data-table-accent': globals['lt-gray-lighter'],
		'data-table-border': globals['lt-gray-lighter'],
		'data-table-filter': globals['lt-gray-lightest'],
		'gray-hover': globals['lt-gray-lightest'],
		'alert-bg': globals.blue,
		'button-bg': globals['lt-gray-lighter'],
		'button-bg-2': globals['lt-gray-light'],
		'button-bg-3': globals['lt-gray-dark'],
		'button-disabled': globals['lt-gray-light'],
		'button-text': globals['lt-white'],
		'border-color': globals['lt-gray-lighter'],
		danger: globals.red,
		success: globals.green,
		'toggle-bg': globals['lt-gray-light'],
		'expander-bg': globals.black,
		'expander-arrow': globals['lt-white'],
		'expander-circle': globals['lt-white'],
		'expander-bg-hover': globals.blue,
		'expander-arrow-hover': globals['lt-white'],
		'expander-circle-hover': globals.blue,
		'icon-color': globals['lt-white'],
		scrollbar: globals['lt-gray-lighter'],
		'scrollbar-hover': globals['lt-gray-light'],
		'box-shadow-color': 'rgba(0, 0, 0, 0.2)',
		'loading-color': 'rgba(249, 250, 252, 0.8)',
	},
}

export default themes
