import React, { useEffect, useState } from 'react'
import { DropdownMenu } from '../exports'

export default function ExportOptions({
	exportToPDF,
	exportToCSV,
	className,
	...otherProps
}) {
	const initialState = [
		{
			label: 'Excel',
			value: 'csv',
			onClick: exportToCSV,
			icon: 'icon-csv-file-format-extension',
		},
		{
			label: 'PDF',
			value: 'pdf',
			onClick: exportToPDF,
			icon: 'icon-pdf-file-format-symbol',
		},
	]
	const [options, setOptions] = useState(initialState)

	useEffect(() => {
		const filterOptions = () => options.filter(option => option.onClick)
		setOptions(filterOptions())
	}, [])

	const handleExport = ({ label, value }) => {
		if (value === 'csv') {
			exportToCSV()
		}
		if (value === 'pdf') {
			exportToPDF()
		}
	}

	// const customStyles = {
	//   menu: {
	//     width: "100px",
	//     right: 0
	//   },
	//   valueContainer: {
	//     fontSize: "var(--font-size-large)"
	//   },
	//   dropdownIndicator: {
	//     display: "none"
	//   },
	//   singleValue: {
	//     top: "56%",
	//     right: "17%"
	//   }
	// }

	return (
		<DropdownMenu
			// buttonDisplay={<i className="icon-export"/>}
			buttonDisplay='Export'
			onChange={handleExport}
			options={options}
			// width='40px'
			// customStyles={customStyles}
		/>
	)
}
