import React from 'react'
import scorecard from '../assets/images/icon_scorecard.svg'
import pipeline from '../assets/images/icon_pipeline.svg'
import admin from '../assets/images/icon_admin.svg'
import dragon from '../assets/images/dragon.png'
import reporting from '../assets/images/icon_reporting.svg'
import etl from '../assets/images/icon_module_etl.svg'

const ScorecardModule = React.lazy(() => import('./scorecard/ScorecardModule'))
const PortalAdminModule = React.lazy(() =>
	import('./portal-admin/PortalAdminModule')
)
const SystemAdminModule = React.lazy(() =>
	import('./system-admin/SystemAdminModule')
)
const PipelineModule = React.lazy(() => import('./pipeline/PipelineModule'))
const ReportingModule = React.lazy(() => import('./reporting/ReportingModule'))
const EnterpriseAdminModule = React.lazy(() =>
	import('./enterprise-admin/EnterpriseAdminModule')
)
const ETLModule = React.lazy(() => import('./etl/ETLModule'))
const LpmModule = React.lazy(() => import('./lpm/LpmModule'))

const getModuleObj = key => {
	switch (key) {
		case 'Quantalytix.Scorecards':
			return {
				component: <ScorecardModule key='01' path='scorecard/*' />,
				image: <img src={scorecard} alt='scorecard module' />,
			}
		case 'Quantalytix.Lender.Admin':
			return {
				component: <PortalAdminModule key='02' path='portal/admin/*' />,
				image: <img src={admin} alt='admin module' />,
			}
		case 'Quantalytix.Pipeline':
			return {
				component: <PipelineModule key='03' path='pipeline/*' />,
				image: <img src={pipeline} alt='pipeline module' />,
			}
		case 'Quantalytix.System.Admin':
			return {
				component: <SystemAdminModule key='04' path='system-admin/*' />,
				image: (
					<img
						className='white-shadow'
						src={dragon}
						alt='system admin module'
					/>
				),
			}
		case 'Quantalytix.Reporting':
			return {
				component: <ReportingModule key='05' path='reporting/*' />,
				image: <img src={reporting} alt='reporting module' />,
			}
		case 'Quantalytix.Enterprise.Admin':
			return {
				component: <EnterpriseAdminModule key='06' path='enterprise/admin/*' />,
				image: <img src={admin} alt='admin module' />,
			}
		case 'Quantalytix.Etl':
			return {
				component: <ETLModule key='07' path='etl/*' />,
				image: <img src={etl} alt='etl module' />,
			}
		case 'Quantalytix.Lpm':
			return {
				component: <LpmModule key='08' path='lpm/*' />,
				image: <img src={reporting} alt='LPM Module' />,
			}
		default:
			return {
				component: <div key='z' path='new-module/*' />,
				image: <i className='icon-cogs1' />,
			}
	}
}

export default getModuleObj
