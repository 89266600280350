import React from 'react'
import { Button } from '../exports'
import { formatData } from '../utils'
import './data-badge.scss'

export default function DataBadge({
	name,
	description,
	primaryData,
	secondaryData,
	isLoading,
	onClick,
	...otherProps
}) {
	const formatUnit = ({ unit, value, unitSingular }) => {
		if (unit) {
			return <span className='unit'>{value !== 1 ? unit : unitSingular}</span>
		}
	}

	return (
		<Button
			className='data-badge'
			onClick={onClick}
			color='bones'
			{...otherProps}
		>
			<div className='name'>{name}</div>
			<div className='description'>{description}</div>
			<div className={'primary-data'}>
				{isLoading ? (
					<div>...</div>
				) : (
					<div>
						{formatData(primaryData.value, primaryData.format, true)}{' '}
						{formatUnit(primaryData)}
					</div>
				)}
			</div>
			<div className={'secondary-data'}>
				{isLoading ? (
					<div>...</div>
				) : (
					<div>
						{formatData(secondaryData.value, secondaryData.format, true)}{' '}
						{formatUnit(secondaryData)}
					</div>
				)}
			</div>
		</Button>
	)
}
