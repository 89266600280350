import React from 'react'
import { TextInput } from 'shared/exports'
import './date-range-picker.scss'

export default function DateRangePicker({
	minDate,
	maxDate,
	values,
	onChange,
}) {
	return (
		<div className='date-range-picker'>
			<div className='input-start'>
				<label htmlFor='start'>Start</label>
				<TextInput
					type='date'
					name='start'
					id='start'
					min={minDate}
					max={maxDate}
					value={values.start}
					onChange={e =>
						onChange({ name: e.target.name, value: e.target.value })
					}
				/>
			</div>
			<div className='input-end'>
				<label htmlFor='end'>End</label>
				<TextInput
					type='date'
					name='end'
					id='end'
					min={minDate}
					max={maxDate}
					value={values.end}
					onChange={e =>
						onChange({ name: e.target.name, value: e.target.value })
					}
				/>
			</div>
		</div>
	)
}
