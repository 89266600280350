import React from 'react'
import { useAuth } from './AuthHooks'
import { FullScreenLoading } from '../shared/exports'

import './auth-layout.scss'

export default function AuthLayout({ children }) {
	const { isLoading } = useAuth()

	if (isLoading) {
		return <FullScreenLoading />
	}
	return (
		<div className='auth-layout'>
			<div className='component-container'>{children}</div>
		</div>
	)
}
