import React, { useEffect, useMemo } from 'react'
import {
	useTable,
	usePagination,
	useSortBy,
	useFilters,
	useRowSelect,
	useGlobalFilter,
	useResizeColumns,
	useFlexLayout,
} from 'react-table'
import GlobalTableFilter from './GlobalTableFilter'
import { Filter, DefaultColumnFilter } from './ColumnTableFilter'
import { IndeterminateCheckbox } from '../exports'
import './q-table.scss'
import Pagination from './Pagination'

export default function QTable({
	data,
	columns,
	striped,
	highlight,
	className,
	tableClassName,
	defaultStyle,
	tableFilterable,
	columnFilterable,
	onRowClick,
	tablePagination,
	rowSelection,
	tableResizing,
	setSelectedRows,
	initialPageIndex,
	initialPageSize,
	sessionStorageUsed,
	...props
}) {
	const generateClassName = () => {
		let css = 'q-table'
		if (defaultStyle) css += ' default'
		if (striped) css += ' -striped'
		if (highlight) css += ' -highlight'
		if (tableClassName) css += ` ${tableClassName}`
		return css
	}

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		selectedFlatRows,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize, selectedRowIds },
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: {
				pageIndex: initialPageIndex || 0,
				pageSize: initialPageSize || 10,
			},
			// autoResetPage: false,
			// autoResetSortBy: false,
		},
		useFilters,
		useGlobalFilter,
		useResizeColumns,
		useFlexLayout,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			if (rowSelection) {
				return hooks.allColumns.push(columns => [
					{
						width: 50,
						id: 'selection',
						Header: ({ getToggleAllPageRowsSelectedProps }) => (
							<div aria-hidden={true} onClick={e => e.stopPropagation()}>
								<IndeterminateCheckbox
									{...getToggleAllPageRowsSelectedProps()}
								/>
							</div>
						),
						Cell: ({ row }) => (
							<div aria-hidden={true} onClick={e => e.stopPropagation()}>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
					},
					...columns,
				])
			}
			hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
				// fix the parent group of the selection button to not be resizable
				const selectionGroupHeader = headerGroups[0].headers[0]
				selectionGroupHeader.canResize = rowSelection ? false : true
			})
		}
	)

	useEffect(() => {
		if (rowSelection) {
			const data = selectedFlatRows.map(d => d.original)
			setSelectedRows(data)
		}
	}, [setSelectedRows, selectedRowIds, selectedFlatRows, rowSelection])

	//this is to track the page number and size the user selects
	useEffect(() => {
		if (sessionStorageUsed) {
			sessionStorage.setItem('table page index', pageIndex)
			sessionStorage.setItem('table page size', pageSize)
		}
	}, [pageIndex, pageSize, sessionStorageUsed])

	// const handleOnRowClick = row => {
	// 	if (rowSelection) {
	// 	}
	// }
	return (
		<div
			className={
				className ? `qtable-container ${className}` : 'qtable-container'
			}
			{...props}
		>
			{tableFilterable ? (
				<div className='search-bar-container'>
					<GlobalTableFilter
						className='search-bar'
						preGlobalFilteredRows={preGlobalFilteredRows}
						// globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
				</div>
			) : null}
			<table {...getTableProps()} className={generateClassName()}>
				<thead className='qtable-table-header'>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th
									{...column.getHeaderProps()}
									className='qtable-column-header'
								>
									<div {...column.getSortByToggleProps()}>
										{column.render('Header')}
										{tableResizing
											? column.canResize && (
													<div
														{...column.getResizerProps()}
														className={`resizer ${
															column.isResizing ? 'isResizing' : ''
														}`}
													></div>
											  )
											: null}
										{sessionStorageUsed ? (
											<button
												onClick={() => sessionStorage.clear('table page index')}
												className='sorting-button'
											>
												{column.isSorted
													? column.isSortedDesc
														? ' 🔽'
														: ' 🔼'
													: ''}
											</button>
										) : (
											<span>
												{column.isSorted
													? column.isSortedDesc
														? ' 🔽'
														: ' 🔼'
													: ''}
											</span>
										)}
									</div>
									<Filter column={column} />
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} className='qtable-table-body'>
					{page.map((row, i) => {
						prepareRow(row)
						return (
							<tr
								{...row.getRowProps()}
								// onClick={() => handleOnRowClick(row)}
								className='qtable-full-row'
							>
								{row.cells.map(cell => {
									return (
										<td
											{...cell.getCellProps()}
											className={`individual-cell ${
												!tableResizing ? 'no-cell-resizing' : ''
											}`}
										>
											{cell.render('Cell')}
										</td>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			{data.length ? null : (
				<div className='no-data-warning'> No data to display</div>
			)}
			{tablePagination ? (
				<Pagination
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageOptions={pageOptions}
				/>
			) : null}
		</div>
	)
}

QTable.defaultProps = {
	defaultStyle: true,
	striped: true,
	tableFilterable: true,
	columnFilterable: true,
	tablePagination: true,
	rowSelection: true,
	tableResizing: true,
	sessionStorageUsed: false,
}
