import React from 'react'
import { useNavigation } from './NavigationProvider'

export default function SidebarMenuHeader({ name }) {
	const { isExpanded, isOverlaid } = useNavigation()

	return (
		<div
			className={
				isExpanded || isOverlaid ? 'module-heading show' : 'module-heading'
			}
		>
			<span>{name}</span>
		</div>
	)
}
