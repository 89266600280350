import React from 'react'
import appInsights from '../../AppInsightsConfig'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import './error-boundary.scss'

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
		}
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
		}
	}

	componentDidCatch(error, info) {
		appInsights.trackException({
			error: error,
			exception: error,
			severityLevel: SeverityLevel.Error,
			properties: { ...info },
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.component !== this.props.component) {
			this.setState({ hasError: false })
		}
	}

	render() {
		if (this.state.hasError) {
			return <ErrorInfo />
		} else {
			return this.props.children
		}
	}
}

function ErrorInfo() {
	return (
		<div className='error-boundary'>
			<div className='fallback-ui'>
				<h3>Oops! An error occurred.</h3>
				<p>
					If this problem persists, please contact us at
					support@quantalytix.com.
				</p>
			</div>
		</div>
	)
}
