import React from 'react'
import { useNavigation } from './NavigationProvider'

export default function SidebarMenuPage({ page, isSelected, permission }) {
	const { isExpanded, isOverlaid, handlePageClick } = useNavigation()

	const getClassName = () => {
		let css = 'module-page'
		if (isExpanded || isOverlaid) {
			css += ' show'
		}
		if (isSelected) {
			css += ' selected'
		}
		if (permission === 'visible') {
			css += ' disabled'
		}
		if (!permission) {
			css += ' unauthorized'
		}
		return css
	}

	return (
		<div
			onClick={() => handlePageClick(page)}
			className={getClassName()}
			data-testid={page.name}
		>
			<span className='text'>{page.name}</span>
		</div>
	)
}
