/* eslint-disable jsx-a11y/no-onchange */
import React from 'react'
import { Button, TextInput } from '../exports'
import './pagination.scss'

export default function Pagination({
	gotoPage,
	previousPage,
	nextPage,
	canPreviousPage,
	canNextPage,
	pageCount,
	pageIndex,
	pageSize,
	setPageSize,
	pageOptions,
}) {
	return (
		<div className='pagination'>
			<div className='pagination-buttons'>
				<Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{'<<'}
				</Button>
				<Button onClick={() => previousPage()} disabled={!canPreviousPage}>
					{'<'}
				</Button>
				<Button onClick={() => nextPage()} disabled={!canNextPage}>
					{'>'}
				</Button>
				<Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{'>>'}
				</Button>
			</div>
			<span className='page-number'>
				Page:
				<strong className='page-index'>{pageIndex + 1}</strong> of{' '}
				<strong className='page-length'>{pageOptions.length}</strong>
			</span>
			<span className='go-to-page-span'>
				<div className='go-to-page-div'>| Go to page: </div>
				<TextInput
					className='go-to-page-input'
					type='number'
					defaultValue={pageIndex + 1}
					onChange={e => {
						const page = e.target.value ? Number(e.target.value) - 1 : 0
						gotoPage(page)
					}}
				/>
			</span>{' '}
			<select
				className='page-select'
				value={pageSize}
				onChange={e => {
					setPageSize(Number(e.target.value))
				}}
			>
				{[10, 20, 30, 40, 50].map(pageSize => (
					<option key={pageSize} value={pageSize}>
						Show {pageSize}
					</option>
				))}
			</select>
		</div>
	)
}
