import React from 'react'
import { TextInput } from '../exports'
import './column-table-filter.scss'

export const Filter = ({ column }) => {
	return (
		<div className='filter'>{column.canFilter && column.render('Filter')}</div>
	)
}

export const DefaultColumnFilter = ({
	column: {
		filterValue,
		setFilter,
		preFilteredRows: { length },
	},
}) => {
	let sessionStorageUsed = sessionStorage.getItem('table page index')
	const onInputChange = e => {
		if (sessionStorageUsed) {
			setFilter(e.target.value || undefined)
			sessionStorage.clear('table page index')
		} else {
			setFilter(e.target.value || undefined)
		}
	}
	return (
		<TextInput
			className='column-filter-input'
			value={filterValue || ''}
			onChange={e => onInputChange(e)}
			placeholder={`search ${length} items`}
		/>
	)
}

DefaultColumnFilter.defaultProps = {
	sessionStorageUsed: false,
}
