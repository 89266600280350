import React, { useState } from 'react'
import './tooltip.scss'

export default function Tooltip({
	children,
	text,
	showOnHover,
	inline,
	...otherProps
}) {
	const [showTip, setShowTip] = useState(false)
	const [showIcon, setShowIcon] = useState(false)

	const generateClassName = () => {
		let css = 'tooltip'
		if (showOnHover) {
			css += ' hoverable'
		} else if (showTip) {
			css += ' show'
		}
		return css
	}
	if (!text) {
		return children
	}
	return (
		<div
			className={`tooltip-container ${inline ? 'inline' : ''}`}
			onMouseEnter={() => setShowIcon(true)}
			onMouseLeave={() => setShowIcon(false)}
		>
			<div className={generateClassName()} {...otherProps}>
				{text}
			</div>
			{children}
			{showOnHover ? null : (
				<div
					className={showIcon ? 'icon fade-in' : 'icon fade-out'}
					onMouseEnter={() => setShowTip(true)}
					onMouseLeave={() => setShowTip(false)}
				>
					<i className='icon-question-circle-regular'></i>
				</div>
			)}
		</div>
	)
}

Tooltip.defaultProps = {
	showOnHover: true,
	inline: false,
}
