import { useState, useEffect } from 'react'

export default function usePasswordValidation(formInputs) {
	const [passwordError, setPasswordError] = useState('')
	const [isPasswordValid, setIsPasswordValid] = useState(false)
	const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false)

	useEffect(() => {
		if (formInputs.password) {
			validatePassword()
		} else {
			setPasswordError('')
		}
	}, [formInputs.password])

	useEffect(() => {
		if (formInputs.retypedPassword) {
			confirmPassword()
		}
	}, [formInputs.retypedPassword, formInputs.password])

	const validatePassword = () => {
		if (formInputs.password.length < 8) {
			setPasswordError('Password must be at least 8 characters.')
			setIsPasswordValid(false)
		} else {
			setPasswordError('')
			setIsPasswordValid(true)
		}
	}

	const confirmPassword = () => {
		if (formInputs.password === formInputs.retypedPassword) {
			if (isPasswordValid) {
				setIsPasswordConfirmed(true)
				setPasswordError('')
			} else {
				setPasswordError('Password must be at least 8 characters.')
			}
		} else {
			setIsPasswordConfirmed(false)
			setPasswordError('Passwords must match.')
		}
	}

	return { passwordError, isPasswordConfirmed }
}
