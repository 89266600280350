import React from 'react'
import './sidebar-menu.scss'
import { useAuth } from '../authentication/AuthHooks'
import SidebarExpander from './SidebarExpander'
import SidebarMenuHeader from './SidebarMenuHeader'
import SidebarMenuPage from './SidebarMenuPage'
import { useNavigation } from './NavigationProvider'

export default function SidebarMenu() {
	const { userObj } = useAuth()
	const {
		isExpanded,
		isOverlaid,
		handleExpanderClick,
		moduleSelected,
		pageSelected,
	} = useNavigation()

	const getClassName = () => {
		let css = 'expandable-menu'
		if (isExpanded) {
			css += ' expanded'
		}

		if (isOverlaid) {
			css += ' overlay'
		}
		return css
	}

	const checkPagePermission = pagePermissionObj => {
		if (pagePermissionObj) {
			let enabled = userObj.claims.includes(pagePermissionObj.enabled)
			let onlyVisible = userObj.claims.includes(pagePermissionObj.visible)
			if (enabled) {
				return true
			} else if (onlyVisible) {
				return 'visible'
			} else {
				return false
			}
		} else {
			return true
		}
	}

	return (
		<div className='sidebar-menu'>
			<div
				className={`mouse-overlay-hover ${
					isExpanded || isOverlaid ? 'show' : ''
					}`}
			>
				{moduleSelected ? (
					<div className={getClassName()}>
						<SidebarMenuHeader name={moduleSelected.name} />
						<div className={isExpanded || isOverlaid ? 'pages show' : 'pages'}>
							{moduleSelected.items
								? moduleSelected.items.map(item => (
									<SidebarMenuPage
										key={item.id}
										page={item}
										isSelected={item === pageSelected}
										permission={checkPagePermission(item.permission)}
									/>
								))
								: null}
						</div>
					</div>
				) : null}
			</div>

			<SidebarExpander isExpanded={isExpanded} onClick={handleExpanderClick} />
		</div>
	)
}
