import React from 'react'
import './button.scss'

const Button = ({
	color,
	type,
	disabled,
	children,
	className,
	...otherProps
}) => {
	const generateClassName = () => {
		let css = 'btn'
		if (color) {
			css += ` btn-${color}`
		}
		// if (disabled === true) {
		//   css += ' btn-disabled';
		// }
		if (className) {
			css += ` ${className}`
		}
		return css
	}

	return (
		<button
			className={generateClassName()}
			type={type || 'button'}
			disabled={disabled}
			{...otherProps}
		>
			{children}
		</button>
	)
}

export default Button
