import React from 'react'
import { useState } from 'react'
import './upload-zone.scss'

export default function UploadZone({
	fileTypes,
	multiple,
	onAddFiles,
	className,
	...otherProps
}) {
	const uploadBtn = React.createRef()
	const [isDraggingOver, setIsDraggingOver] = useState(false)

	const handleDragIn = e => {
		e.preventDefault()
		e.stopPropagation()
		setIsDraggingOver(true)
	}

	const handleDragOut = e => {
		e.preventDefault()
		e.stopPropagation()
		setIsDraggingOver(false)
	}

	const handleDragOver = e => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDrop = e => {
		e.preventDefault()
		e.stopPropagation()
		onAddFiles(e.dataTransfer.files)
		setIsDraggingOver(false)
	}

	return (
		<div
			className={`upload-zone ${
				isDraggingOver ? 'drag-over' : ''
			} ${className}`}
			onDragEnter={e => handleDragIn(e)}
			onDragLeave={e => handleDragOut(e)}
			onDragOver={e => handleDragOver(e)}
			onDrop={e => handleDrop(e)}
			onClick={() => uploadBtn.current.click()}
			{...otherProps}
		>
			{isDraggingOver ? (
				<div
					onDragOver={e => handleDragOver(e)}
					onDragEnter={e => handleDragIn(e)}
					onDragLeave={e => handleDragIn(e)}
					onDrop={e => handleDrop(e)}
				>
					Drop here to upload!
				</div>
			) : (
				<div className='content'>
					<div className='options'>
						<div className='option'>
							<i className='icon-dragndrop' />
							Drag to Upload Files
						</div>
						<div className='or'>or</div>
						<div className='option'>
							<i className='icon-folder' />
							Select Files to Upload
						</div>
					</div>
					{fileTypes ? <div className='file-types'>({fileTypes})</div> : null}
				</div>
			)}
			<input
				className='hidden'
				ref={uploadBtn}
				type='file'
				accept={fileTypes}
				multiple={multiple ? true : false}
				onChange={() => onAddFiles(Array.from(uploadBtn.current.files))}
			/>
		</div>
	)
}
