import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table-6'
import { LoadingAnimation } from '../exports'
import './pivot-table.scss'

export default function PivotTable({
	tableRef,
	className,
	renderColumnData,
	pivotKeys,
	tablePivotKeys,
	data,
	loading,
	...otherProps
}) {
	const [isExpanded, setIsExpanded] = useState({})
	const [colsToShow, setColsToShow] = useState([])

	useEffect(() => {
		setNewColumns(isExpanded)
	}, [pivotKeys])

	const onExpandedChange = newExpanded => {
		setNewColumns(newExpanded)
	}

	const setNewColumns = rowsExpanded => {
		let newCols = []
		if (tablePivotKeys.length < 1) newCols.push(pivotKeys[0])
		else {
			newCols.push(pivotKeys[0])
			pivotKeys.forEach((pk, i) => {
				if (i === 1) {
					//check top level
					if (isTopLevelRowExpanded(rowsExpanded)) {
						newCols.push(pk)
					}
				} else if (i === 2) {
					// check sub level
					if (isSubLevelRowExpanded(rowsExpanded)) {
						newCols.push(pk)
					}
				}
			})
		}
		setIsExpanded(rowsExpanded)
		setColsToShow(newCols)
	}

	const isTopLevelRowExpanded = newExpandedObj => {
		let expanded = false
		let values = Object.values(newExpandedObj) // if row is expanded, newExpanded === { [rowNumber]: {} }
		values.forEach(v => {
			if (typeof v === 'object') {
				expanded = true
			}
		})
		return expanded
	}

	const isSubLevelRowExpanded = newExpandedObj => {
		let expanded = false
		let values = Object.values(newExpandedObj)
		values.forEach(v => {
			if (typeof v === 'object') {
				let subValues = Object.values(v)
				subValues.forEach(sv => {
					if (typeof sv === 'object') {
						expanded = true
					}
				})
			}
		})
		return expanded
	}

	return (
		<ReactTable
			ref={tableRef}
			data={data}
			resolveData={data => data}
			columns={renderColumnData(colsToShow)}
			className={`-striped -highlight pivot-table ${className}`}
			loading={loading}
			loadingText={<LoadingAnimation />}
			expanded={isExpanded}
			pivotBy={tablePivotKeys}
			onExpandedChange={(newExpanded, index, event) => {
				onExpandedChange(newExpanded)
			}}
			{...otherProps}
		/>
	)
}
