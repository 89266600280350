import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css';
import appInsights from './AppInsightsConfig'
import LegacyAuthProvider from 'authentication/legacyAuthentication/LegacyAuthProvider'
import App from './App'
import * as craServiceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'development') {
	import('react-axe').then(axe => {
		axe.default(React, ReactDOM, 1000)
	})
	const { worker } = require('./mocks/browser')
	worker.start()
}

ReactDOM.render(
	<LegacyAuthProvider>
		<App />
	</LegacyAuthProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
craServiceWorker.unregister()
appInsights.loadAppInsights()
