import React from 'react'
import './tabs.scss'

// [
//   {
//     onClick: updateStagesAndGraph,
//     text: "Hightest Stage Only",
//     value: true,
//     selectedValue: dataFilters.maxStageOnly
//   },
//   {
//     onClick: updateStagesAndGraph,
//     text: "Full Loan History",
//     value: false,
//     selectedValue: dataFilters.maxStageOnly
//   }
// ]

export default function Tabs({
	tabData,
	inHeader,
	accentColor,
	...otherProps
}) {
	return (
		<div id='tabs' {...otherProps}>
			{tabData.map((tab, i) => (
				<Tab key={i} accentColor={accentColor} inHeader={inHeader} {...tab} />
			))}
		</div>
	)
}
Tabs.defaultProps = {
	accentColor: 'var(--blue)',
}

const Tab = ({
	onClick,
	text,
	value,
	inHeader,
	selectedValue,
	accentColor,
}) => {
	const getClassName = () => {
		let css = 'tab'
		if (inHeader) {
			css += ' header-tab'
		}
		return css
	}

	return (
		<button
			style={{ borderBottomColor: accentColor }}
			onClick={onClick}
			value={value}
			id={value === selectedValue ? 'selected' : null}
			className={getClassName()}
		>
			{text}
		</button>
	)
}
