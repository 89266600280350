import React from 'react'
import { appendClassName } from '../utils'
import { QSelect } from '../exports'

export default function DropdownMenu({
	className,
	buttonDisplay,
	onChange,
	options,
	...otherProps
}) {
	return (
		<QSelect
			className={appendClassName('static-select', className)}
			{...otherProps}
			data-testid='static-select'
			options={options}
			value={{ label: buttonDisplay, value: 'staticOption' }}
			onChange={onChange ? onChange : props => props.onClick()}
			{...otherProps}
		/>
	)
}
