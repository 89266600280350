import React from 'react'
import { InputMask, Button } from 'shared/exports'
import { isValidDateRange } from 'shared/utils'
import './date-range-selector.scss'

export default function DateRangeSelecter({
	startDate,
	endDate,
	changeDateRange,
	className,
	noButton,
	hideLabel,
	...otherProps
}) {
	const onSubmit = e => {
		e.preventDefault()
		let { startDate, endDate } = e.target.elements
		if (isValidDateRange(startDate.value, endDate.value)) {
			changeDateRange(startDate.value, endDate.value)
		} else {
			alert('Invalid date range.')
		}
	}

	return (
		<form
			className={
				className ? `date-range-selector ${className}` : 'date-range-selector'
			}
			onSubmit={onSubmit}
			{...otherProps}
		>
			<label htmlFor='startDate' className={hideLabel ? 'hide' : 'label'}>
				From
			</label>
			<InputMask
				name='startDate'
				className='m-r-1'
				mask='99/99/9999'
				placeholder='Start Date'
				defaultValue={startDate}
				data-testid='start-date'
				required
			/>
			<label htmlFor='endDate' className={hideLabel ? 'hide' : 'label'}>
				To
			</label>
			<InputMask
				name='endDate'
				mask='99/99/9999'
				placeholder='End Date'
				defaultValue={endDate}
				data-testid='end-date'
				required
			/>
			<Button
				className={noButton ? 'date-submit' : 'hide'}
				color='muted'
				type='submit'
				data-testid='date-submitBtn'
			>
				Set
			</Button>
		</form>
	)
}
