import React from 'react'
import { Slider, Handles, Tracks, Rail, Ticks } from 'react-compound-slider'
import './minmaxslider.scss'

export default function MinMaxSlider({
	domain,
	values,
	onChange,
	ticksCount = 5,
}) {
	function Handle({ handle: { id, value, percent }, getHandleProps }) {
		return (
			<div
				className='slider-handles'
				aria-label={value}
				style={{
					left: `${percent}%`,
				}}
				{...getHandleProps(id)}
			></div>
		)
	}
	function Track({ source, target, getTrackProps }) {
		return (
			<div
				className='slider-tracks'
				style={{
					left: `${source.percent}%`,
					width: `${target.percent - source.percent}%`,
				}}
				aria-label={`track-${source.value}`}
				{...getTrackProps()}
			/>
		)
	}

	function Tick({ tick, count }) {
		return (
			<div>
				<div
					style={{
						position: 'absolute',
						marginTop: 52,
						marginLeft: -0.5,
						width: 1,
						height: 8,
						backgroundColor: 'silver',
						left: `${tick.percent}%`,
					}}
				/>
				<div
					style={{
						position: 'absolute',
						marginTop: 60,
						fontSize: 10,
						textAlign: 'center',
						marginLeft: `${-(100 / count) / 2}%`,
						width: `${100 / count}%`,
						left: `${tick.percent}%`,
					}}
				>
					{tick.value}
				</div>
			</div>
		)
	}

	return (
		<Slider
			className='slider'
			domain={domain}
			step={1}
			mode={2}
			values={values}
			onChange={onChange}
		>
			<Rail>
				{({ getRailProps }) => (
					<div aria-label='slider-rail' className='rail' {...getRailProps()} />
				)}
			</Rail>
			<Handles>
				{({ handles, getHandleProps }) => (
					<div>
						{handles.map(handle => {
							return (
								<Handle
									key={handle.id}
									handle={handle}
									getHandleProps={getHandleProps}
								/>
							)
						})}
					</div>
				)}
			</Handles>
			<Tracks left={false} right={false}>
				{({ tracks, getTrackProps }) => {
					return (
						<div>
							{tracks.map(({ id, source, target }) => (
								<Track
									key={id}
									source={source}
									target={target}
									getTrackProps={getTrackProps}
								/>
							))}
						</div>
					)
				}}
			</Tracks>
			<Ticks count={ticksCount}>
				{({ ticks }) => (
					<div className='slider-ticks'>
						{ticks.map(tick => (
							<Tick key={tick.id} tick={tick} count={ticks.length} />
						))}
					</div>
				)}
			</Ticks>
		</Slider>
	)
}
