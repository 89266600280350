import React from 'react'
import './toggle.scss'

export default function Toggle({
	className,
	selected,
	handleChange,
	...otherProps
}) {
	const generateClassName = () => {
		let name = 'toggle'
		if (className) name += ` ${className}`
		if (selected) name += ' selected'
		return name
	}

	return (
		<div
			className={generateClassName()}
			onClick={() => handleChange()}
			{...otherProps}
		>
			<div className='indicator'></div>
		</div>
	)
}
