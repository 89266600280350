import React from 'react'
import './panel.scss'

const Panel = ({ className, children, ...otherProps }) => {
	const generateClassName = () => {
		let css = 'panel'
		if (className) css += ` ${className}`
		return css
	}
	return (
		<div className={generateClassName()} {...otherProps}>
			{children}
		</div>
	)
}

export default Panel
