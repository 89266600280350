import React from 'react'
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../exports'
import qlogo from '../assets/images/logo_black_bg.svg'
import './logo-box.scss'

export default function LogoBox({ className, children, logo, footer }) {
	return (
		<Panel className={className ? `logo-box ${className}` : 'logo-box'}>
			<PanelHeader>
				{logo ? (
					logo
				) : (
					<img
						className='logo'
						src={qlogo}
						alt='Quantalytix Logo'
						height='25px'
					/>
				)}
			</PanelHeader>
			<PanelBody>{children}</PanelBody>
			{footer ? <PanelFooter>{footer}</PanelFooter> : null}
		</Panel>
	)
}
