import React from 'react'
import { LoadingAnimation } from '../exports'
import './loader.scss'

export default function Loader({ isLoading, ...otherProps }) {
	return (
		<div
			className={isLoading ? 'new-loader visible' : 'new-loader'}
			{...otherProps}
		>
			<LoadingAnimation />
		</div>
	)
}
