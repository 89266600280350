import React from 'react'

export default function NotFound() {
	return (
		<div style={{ margin: '20px', fontSize: 'large' }}>
			<p className='m-1'>
				Oops! It looks like there is nothing at this address or you are not
				authorized to view this screen.
			</p>
			<p className='m-1'>
				If you think this is an error, please contact{' '}
				<a href='mailto:support@quantalytix.com'>support@quantalytix.com</a>.
			</p>
		</div>
	)
}
