import React from 'react'
import './data-filter-bar.scss'
import { useNavigation } from '../../navigation/NavigationProvider'

export default function DataFilterBar({ className, children }) {
	const { isExpanded } = useNavigation()

	const generateClassName = () => {
		let css = 'data-filter-bar'
		if (isExpanded) css += ' expanded'
		if (className) {
			css += ` ${className}`
		}
		return css
	}

	return (
		<div className='data-filter-bar-container'>
			<div className='background-box'></div>
			<div className={generateClassName()}>{children}</div>
		</div>
	)
}
