import React from 'react'

const PanelFooter = ({ noPadding, defaultStyle, className, children }) => {
	const generateClassName = () => {
		let css = 'panel-footer'
		if (defaultStyle === false) css = ''
		if (noPadding === true) css += ' no-padding'
		if (className != null) css += ` ${className}`
		return css
	}
	return <div className={generateClassName()}>{children}</div>
}

export default PanelFooter
