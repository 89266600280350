import React, { useState } from 'react'
import {
	LogoBox,
	Alert,
	Button,
	TextInput,
	usePasswordValidation,
	useCanSubmitForm,
} from '../exports'

export default function PasswordBox({
	onSubmit,
	resetAlert,
	title,
	alertMessage,
	requestPrevious,
	footer,
}) {
	const [passwordInputs, setPasswordInputs] = useState({
		password: '',
		retypedPassword: '',
		oldPassword: '',
	})
	const { passwordError, isPasswordConfirmed } = usePasswordValidation(
		passwordInputs
	)
	const canSubmit = useCanSubmitForm(
		requestPrevious
			? passwordInputs
			: {
					password: passwordInputs.password,
					retypedPassword: passwordInputs.retypedPassword,
			  },
		isPasswordConfirmed
	)

	const handleOnChange = e => {
		resetAlert()
		setPasswordInputs({
			...passwordInputs,
			[e.target.name]: e.target.value,
		})
	}

	const handleOnSubmit = e => {
		e.preventDefault()
		onSubmit(passwordInputs)
	}

	return (
		<LogoBox footer={footer}>
			<Alert
				hidden={alertMessage ? false : true}
				dismissible={true}
				onCloseAlert={resetAlert}
			>
				{alertMessage}
			</Alert>
			<Alert hidden={passwordError ? false : true} dismissible={false}>
				{passwordError}
			</Alert>
			<form className='form' onSubmit={handleOnSubmit}>
				<h3>{title}</h3>
				{requestPrevious ? (
					<TextInput
						placeholder='Old Password'
						type='password'
						name='oldPassword'
						required={true}
						value={passwordInputs.oldPassword}
						onChange={handleOnChange}
						data-testid='old-password'
					/>
				) : null}
				<TextInput
					placeholder='Create Password'
					type='password'
					name='password'
					required={true}
					value={passwordInputs.password}
					onChange={handleOnChange}
					data-testid='reset-password'
				/>
				<TextInput
					placeholder='Confirm Password'
					type='password'
					name='retypedPassword'
					required={true}
					value={passwordInputs.retypedPassword}
					onChange={handleOnChange}
					data-testid='reset-password_confirmation'
				/>
				<div className='btn-container'>
					<Button
						type='submit'
						color='bones'
						disabled={!canSubmit}
						data-testid='reset-submit'
					>
						Submit
					</Button>
				</div>
			</form>
		</LogoBox>
	)
}
