import React from 'react'
import { Panel, PanelHeader, PanelBody, Table } from '../exports'
import './chart-tooltip.scss'

export default function ChartTooltip({
	className,
	header,
	headerClassName,
	data,
	table,
}) {
	return (
		<Panel className={`chart-tooltip ${className}`}>
			{header ? (
				<PanelHeader
					border={false}
					className={headerClassName || 'chart-tooltip-header'}
				>
					{header}
				</PanelHeader>
			) : null}
			<PanelBody className='chart-tooltip-body' noPadding={true}>
				{table || (
					<Table
						data={data}
						defaultStyle={false}
						striped={false}
						highlight={false}
					/>
				)}
			</PanelBody>
		</Panel>
	)
}
