import React from 'react'
import SidebarIcon from './SidebarIcon'
import getModuleObj from '../modules/moduleObj'
import SidebarMenu from './SidebarMenu'
import AppVersion from 'AppVersion'
import { useNavigation } from './NavigationProvider'
import './q-sidebar.scss'

export default function QSidebar() {
	const {
		handleModuleIconClick,
		currentModule,
		handleOverlayShow,
		handleOverlayHide,
	} = useNavigation()
	const { filteredModules } = useNavigation()

	return (
		<div className='sidebar' onMouseLeave={handleOverlayHide}>
			<div className='icons'>
				{Object.values(filteredModules).map(m => {
					return (
						<SidebarIcon
							key={m.id}
							currentModule={
								currentModule ? currentModule.moduleKey === m.moduleKey : false
							}
							module={m}
							icon={getModuleObj(m.moduleKey).image}
							onClick={handleModuleIconClick}
							onMouseEnter={handleOverlayShow}
						/>
					)
				})}
			</div>
			<SidebarMenu />
			<AppVersion />
		</div>
	)
}
