import React, { Component } from 'react'

export default class DateInput extends Component {
	constructor(props) {
		super(props)

		this.state = {
			value: '',
		}

		this.validate = this.validate.bind(this)
	}

	validate(e) {
		let v = e.target.value.replace(/\D/g, '').slice(0, 10)
		if (v.length >= 5) {
			v = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
		} else if (v.length >= 3) {
			v = `${v.slice(0, 2)}/${v.slice(2)}/`
		}
		/*
    else if (v.length === 2) {
      v = `${v.slice(0, 2)}/`;
    }*/

		this.setState({ value: v })
	}

	render() {
		return (
			<input
				type='text'
				name='date'
				placeholder='mm/dd/yyyy'
				onChange={this.validate}
				maxLength='10'
				value={this.state.value}
			/>
		)
	}
}

//https://stackoverflow.com/questions/31108620/how-to-do-date-masking-using-javascript-without-jquery
