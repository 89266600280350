import React from 'react'
import './loading-animation.scss'

export default function LoadingAnimation({
	className,
	color,
	size,
	...otherProps
}) {
	return (
		<div className={`lds-ellipsis ${className || ''}`} {...otherProps}>
			<div
				style={{
					background: color || 'var(--text-muted)',
					width: size || '11px',
					height: size || '11px',
				}}
			></div>
			<div
				style={{
					background: color || 'var(--text-muted)',
					width: size || '11px',
					height: size || '11px',
				}}
			></div>
			<div
				style={{
					background: color || 'var(--text-muted)',
					width: size || '11px',
					height: size || '11px',
				}}
			></div>
			<div
				style={{
					background: color || 'var(--text-muted)',
					width: size || '11px',
					height: size || '11px',
				}}
			></div>
		</div>
	)
}
