import React from 'react'
import './sidebar-expander.scss'

export default function SidebarExpander({
	isExpanded,
	onClick,
	...otherProps
}) {
	return (
		<button onClick={onClick} className='expander-arrow'>
			{isExpanded ? (
				<span className='icon-close'>
					<span className='path1'></span>
					<span className='path2'></span>
					<span className='path3'></span>
				</span>
			) : (
				<span className='icon-open'>
					<span className='path1'></span>
					<span className='path2'></span>
					<span className='path3'></span>
				</span>
			)}
		</button>
	)
}
