import React from 'react'
import './textarea.scss'

const TextArea = ({ className, required, ...otherProps }) => (
	<div className='text-area-container'>
		<textarea
			className={`text-area ${className}`}
			required={required}
			{...otherProps}
		></textarea>
		{required ? <span className='required-indicator'></span> : null}
	</div>
)

export default TextArea

TextArea.defaultProps = {
	className: '',
}
