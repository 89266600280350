const menuArray = [
	{
		id: 1,
		order: 100,
		name: 'Scorecard',
		moduleKey: 'Quantalytix.Scorecards',
		items: [
			{
				id: 1,
				name: 'Lender Scorecard',
				route: '/scorecard/lender',
				icon: 'icon-address-card-regular',
				order: 100,
				permission: {
					enabled: 'scorecard.enabled',
					visible: 'scorecard.menuItem.visible',
				},
			},
			{
				id: 2,
				name: 'Portfolio',
				route: '/scorecard/portfolio',
				icon: 'icon-folder-open-solid',
				order: 200,
				permission: {
					enabled: 'portfolio.enabled',
					visible: 'portfolio.menuItem.visible',
				},
			},
			{
				id: 3,
				name: 'Production',
				route: '/scorecard/production',
				icon: 'icon-trophy1',
				order: 300,
				permission: {
					enabled: 'production.enabled',
					visible: 'production.menuItem.visible',
				},
			},
			{
				id: 4,
				name: 'Audit',
				route: '/scorecard/audit',
				icon: 'icon-clipboard1',
				order: 400,
				permission: {
					enabled: 'audit.enabled',
					visible: 'audit.menuItem.visible',
				},
			},
		],
	},
	{
		id: 2,
		order: 900,
		name: 'Administration',
		moduleKey: 'Quantalytix.Lender.Admin',
		permission: {
			enabled: 'admin',
			visible: 'lender.admin.menuItem.visible',
		},
		items: [
			{
				id: 1,
				name: 'Manage Users',
				route: '/portal/admin',
				icon: 'icon-address-card-regular',
				order: 100,
				permission: {
					enabled: 'admin',
					visible: 'lender.admin.menuItem.visible',
				},
			},
		],
	},
	{
		id: 3,
		order: 920,
		name: 'System Admin',
		moduleKey: 'Quantalytix.System.Admin',
		permission: {
			enabled: 'system.admin.enabled',
			visible: 'system.admin.menuItem.visible',
		},
		items: [
			{
				id: 1,
				name: 'App Version',
				route: '/system-admin/app',
				icon: 'icon-address-card-regular',
				order: 100,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 2,
				name: 'Enterprises',
				route: '/system-admin/enterprises',
				icon: 'icon-address-card-regular',
				order: 200,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 3,
				name: 'Modules',
				route: '/system-admin/modules',
				icon: 'icon-address-card-regular',
				order: 300,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 4,
				name: 'Data Transfer Log',
				route: '/system-admin/data-transfer-log',
				icon: 'icon-address-card-regular',
				order: 400,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 5,
				name: 'Data Commands',
				route: '/system-admin/data-commands',
				icon: 'icon-address-card-regular',
				order: 500,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 6,
				name: 'Domains',
				route: '/system-admin/domains',
				icon: 'icon-address-card-regular',
				order: 600,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 7,
				name: 'Keys',
				route: '/system-admin/keys',
				icon: 'icon-address-card-regular',
				order: 700,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 8,
				name: 'Subscriptions',
				route: '/system-admin/subscriptions',
				icon: 'icon-address-card-regular',
				order: 800,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 9,
				name: 'Users',
				route: '/system-admin/users',
				icon: 'icon-address-card-regular',
				order: 900,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
			{
				id: 10,
				name: 'Emails',
				route: '/system-admin/emails',
				icon: 'icon-address-card-regular',
				order: 1000,
				permission: {
					enabled: 'system.admin.enabled',
					visible: 'system.admin.menuItem.visible',
				},
			},
		],
	},
	{
		id: 4,
		order: 910,
		name: 'Administration',
		moduleKey: 'Quantalytix.Enterprise.Admin',
		permission: {
			enabled: 'enterprise.admin.enabled',
			visible: 'enterprise.admin.menuItem.visible',
		},
		items: [
			{
				id: 1,
				name: 'Lender Accounts',
				route: '/enterprise/admin/lender-accounts',
				icon: 'icon-address-card-regular',
				order: 100,
				permission: {
					enabled: 'enterprise.admin.enabled',
					visible: 'enterprise.admin.menuItem.visible',
				},
			},
		],
	},
	{
		id: 5,
		order: 200,
		name: 'Pipeline',
		moduleKey: 'Quantalytix.Pipeline',
		permission: {
			enabled: 'pipeline.enabled',
			visible: 'pipeline.menuItem.visible',
		},
		items: [
			{
				id: 1,
				name: 'Pipeline Dashboard',
				route: '/pipeline/dashboard',
				icon: 'icon-address-card-regular',
				order: 100,
				permission: {
					enabled: 'pipeline.enabled',
					visible: 'pipeline.menuItem.visible',
				},
			},
		],
	},
	{
		id: 6,
		order: 300,
		name: 'Reporting',
		moduleKey: 'Quantalytix.Reporting',
		permission: {
			enabled: 'reporting.enabled',
			visible: 'reporting.menuItem.visible',
		},
		items: [
			{
				id: 1,
				name: 'Settlement Files',
				route: '/reporting/settlement',
				icon: 'icon-address-card-regular',
				order: 100,
				permission: {
					enabled: 'settlement.enabled',
					visible: 'settlement.menuItem.visible',
				},
			},
			{
				id: 2,
				name: 'VirPack Reporting',
				route: '/reporting/virpack',
				permission: {
					enabled: 'virpack.enabled',
					visible: 'virpack.menuItem.visible',
				},
			},
		],
	},
	{
		id: 10,
		order: 400,
		name: 'ETL',
		moduleKey: 'Quantalytix.Etl',
		items: [
			{
				id: 1,
				name: 'Data Connections',
				route: '/etl/connections',
				icon: '',
				order: 100,
			},
			{
				id: 2,
				name: 'Data Tables',
				route: '/etl/datatables',
				icon: '',
				order: 200,
			},
			{
				id: 3,
				name: 'Mappings',
				route: '/etl/mappings',
				icon: '',
				order: 300,
			},
			{
				id: 4,
				name: 'Transformations',
				route: '/etl/transformations',
				icon: '',
				order: 400,
			},
			{
				id: 5,
				name: 'Quality Assurances',
				route: '/etl/qa',
				icon: '',
				order: 500,
			},
			{
				id: 6,
				name: 'Load Routines',
				route: '/etl/load',
				icon: '',
				order: 600,
			},
		],
	},
	{
		id: 11,
		order: 500,
		name: 'Loan Portfolio Monitoring',
		moduleKey: 'Quantalytix.Lpm',
		// permission: {
		// 	enabled: 'lpm.enabled',
		// 	visible: 'lpm.visible',
		// },
		items: [
			{
				id: 1,
				name: 'Delinquency Reporting',
				route: '/lpm/delinquencies',
				// permission: {
				// 	enabled: 'delinquency.enabled',
				// 	visible: 'delinquency.menuItem.visible',
				// },
			},
		],
	},
]

export default menuArray
