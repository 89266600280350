import React, { Suspense } from 'react'
import { Router, Link } from '@reach/router'
import LoginRoutes from 'authentication/LoginRoutes'
import { useAlerts } from 'authentication/AuthHooks'
import QHeader from 'navigation/QHeader'

import {
	Loader,
	ScreenAlertContainer,
	Alert,
	ErrorBoundary,
} from './shared/exports'

const RegistrationRoutes = React.lazy(() =>
	import('./authentication/RegistrationRoutes')
)
const VerificationRoutes = React.lazy(() =>
	import('./authentication/VerificationRoutes')
)
const PasswordRoutes = React.lazy(() =>
	import('./authentication/PasswordRoutes')
)

export default function MainContent({ className }) {
	const { alertObj, serverError, setAlertObj } = useAlerts()

	const loginLink = (text, key) => (
		<Link className='link' to={key ? `/portal/${key}` : '/login'}>
			{text}
		</Link>
	)
	const resendLink = text => (
		<Link className='link' to='/verify/resend'>
			{text}
		</Link>
	)
	return (
		<div className='app-layout logged-out'>
			<QHeader />
			<ErrorBoundary>
				<div className='main-container'>
					<ScreenAlertContainer>
						<Alert
							hidden={serverError ? false : true}
							type='danger'
							dismissible={true}
							onCloseAlert={() => setAlertObj({ ...alertObj, serverError: '' })}
						>
							{serverError}
						</Alert>
					</ScreenAlertContainer>
					<Suspense fallback={<Loader isLoading={true} />}>
						<Router>
							<LoginRoutes path='login/*' default />
							<RegistrationRoutes path='/register/*' loginLink={loginLink} />
							<VerificationRoutes
								path='/verify/*'
								loginLink={loginLink}
								resendLink={resendLink}
							/>
							<PasswordRoutes path='/password/*' loginLink={loginLink} />
						</Router>
					</Suspense>
				</div>
			</ErrorBoundary>
		</div>
	)
}
