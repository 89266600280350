import { themes } from './exports'

const isValidDateRange = (start, end) => {
	let startObj = new Date(start)
	let endObj = new Date(end)
	let isStartBeforeEnd = startObj <= endObj
	let startDay = start.split('/')[1]
	let endDay = end.split('/')[1]
	let isValidStart =
		Boolean(+startObj) && startObj.getDate() === parseInt(startDay, 10)
	let isValidEnd = Boolean(+endObj) && endObj.getDate() === parseInt(endDay, 10)
	return isStartBeforeEnd && isValidStart && isValidEnd
}

const getDarkThemeColor = colorString => {
	let match = colorString.match(/(?<=--)(.*?)(?=\))/gm)
	let color = themes.dark[match]
	if (!match || !color) {
		console.error(`no dark theme color match for ${colorString}.`)
		return colorString
	}
	return color
}

const formatData = (data, format, round = false) => {
	switch (format) {
		case 'number':
			return numberWithCommas(data)
		case 'currency':
			return intToCurrency.format(data)
		case 'percent':
			return convertToPercent(data, round)
		default:
			return data
	}
}

const getLastWeekdayDate = () => {
	let dayOfWeek = getCurrentDate('D')
	let daysToSubtract
	if (dayOfWeek === 'Sunday') {
		daysToSubtract = 2
	} else if (dayOfWeek === 'Monday') {
		daysToSubtract = 3
	} else {
		daysToSubtract = 1
	}
	return formatDate(new Date().setDate(new Date().getDate() - daysToSubtract), {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
	})
}

const getYTD = () => {
	let month = getCurrentDate('m')
	let day = getCurrentDate('dd')
	if (month === '02' && day === '29') {
		day = '28'
	}

	let year = getCurrentDate('yyyy') - 1
	return `${month}/${day}/${year}`
}

const convertToPercent = (num, round = false) => {
	let result
	if (round) {
		result = Math.round(num * 100)
	} else {
		result = (num * 100).toFixed(2)
	}
	return num === 0 ? '0%' : `${result}%`
}

const appendClassName = (parentClassName, className = null) => {
	let css = parentClassName
	if (className) {
		css += ` ${className}`
	}
	return css
}

const slugify = string => {
	const a =
		'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
	const b =
		'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------'
	const p = new RegExp(a.split('').join('|'), 'g')

	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w-]+/g, '') // Remove all non-word characters
		.replace(/--+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, '') // Trim - from end of text
}

const sleep = ms => {
	return new Promise(resolve => setTimeout(resolve, ms))
}

const handleErrorMessage = code => {
	switch (code) {
		case 1:
			return 'There is not an account associated with that email address.'
		case 2:
			return 'Incorrect password. Try again!'
		case 3:
			return 'This link has expired.'
		case 4:
			return 'An account with this email address already exists.'
		case 5:
			return 'Another user already updated this information. Try again!'
		case 6:
			return 'This account has not been confirmed.  Please check your email for a confirmation link.'
		case 7:
			return 'Account is currently locked.'
		case 8:
			return 'Account has been disabled.'
		case 9:
			return 'We do not recognize this email domain. Only current clients of Quantalytix are able to create an account.'
		case 10:
			return 'You need to log in before being able to access this screen.'
		case 11:
			return 'This account has already been confirmed.'
		default:
			return 'Oops! Something went wrong.'
	}
}

const newCheckForErrors = (resp, customMessage) => {
	let alertObj = {
		serverError: '',
		displayMessage: '',
	}
	if (resp.failed) {
		alertObj.serverError =
			customMessage ||
			'We are unable to process your request right now. Please try again later.'
	} else if (resp.error) {
		alertObj.displayMessage = handleErrorMessage(resp.error.code)
	}
	return alertObj
}

const formatDate = (date, optionsObj) => {
	if (date) {
		let d = new Date(date)
		return d.toLocaleDateString('en-US', optionsObj)
	} else {
		return null
	}
}

const parseURL = (path = window.location.href) => {
	let params = {}
	path.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (_m, key, value) {
		params[key] = value === 'null' ? null : value
	})
	params.token = decodeURIComponent(params.token)
	params.code = decodeURIComponent(params.code)
	return params
}

const intToCurrency = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0,

	// intToCurrency.format(1000) // "$1,000.00"
})

const numberWithCommas = num => {
	if (typeof num === 'number') {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	} else {
		return 'missing data'
	}
}

const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1)

const compareObjStrings = (a, b, order = 'asc') => {
	if (order === 'asc') {
		return a.value > b.value ? -1 : a.value < b.value ? 1 : 0
	} else {
		return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
	}
}

const getCurrentDate = format => {
	const currentDate = new Date()
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	const days = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	]

	switch (format) {
		case 'yyyy':
			return currentDate.getFullYear()
		case 'M':
			return months[currentDate.getMonth()]
		case 'm':
			let m = (currentDate.getMonth() + 1).toString()
			return m.length === 1 ? `0${m}` : m
		case 'd':
			return currentDate.getDate()
		case 'dd':
			let day = currentDate.getDate().toString()
			return day.length === 1 ? `0${day}` : day
		case 'D':
			return days[currentDate.getDay()]
		case 'yyyymmdd':
			let month = currentDate.getMonth()
			let monthString =
				month < 9 ? '0' + (month + 1).toString() : (month + 1).toString()
			let date = currentDate.getDate()
			let dateString = date < 9 ? '0' + date.toString() : date.toString()
			return currentDate.getFullYear() + monthString + dateString
		default:
			return currentDate.toLocaleDateString('en-US', {
				year: 'numeric',
				day: '2-digit',
				month: '2-digit',
			})
	}
}

const getExtensionFromType = type => {
	switch (type) {
		case 'application/pdf':
			return 'pdf'
		case 'image/jpeg':
			return 'jpg'
		case 'image/png':
			return 'png'
		case 'text/plain':
			return 'txt'
		case 'text/csv':
			return 'csv'
		case 'text/html':
			return 'html'
		default:
			return 'csv'
	}
}

const formatFileSize = size => {
	if (size < 1024) return size + ' bytes'
	else if (size > 1024 && size < 1048576)
		return (size / 1024).toFixed(1) + ' KB'
	else return (size / 1048576).toFixed(1) + ' MB'
}

const states = [
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Missisippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' },
	{ value: 'DC', label: 'District of Columbia' },
	{ value: 'AS', label: 'American Samoa' },
	{ value: 'GU', label: 'Guam' },
	{ value: 'MP', label: 'Northern Mariana Islands' },
	{ value: 'PR', label: 'Puerto Rico' },
	{ value: 'VI', label: 'Virgin Islands' },
]

const groupBy = (dataArray, property, options = { removeProperty: true }) => {
	return dataArray.reduce((acc, obj) => {
		let key = obj[property]
		if (!acc[key]) {
			acc[key] = []
		}
		if (options.removeProperty === false) {
			let { period, ...rest } = obj
			acc[key].push({ ...rest })
		}
		for (let param in obj) {
			if (obj[param] === key) {
				delete obj[param]
			}
		}
		let { period, ...rest } = obj
		acc[key].push({ ...rest })
		return acc
	}, {})
}

const getFirstDayOfYear = () => {
	let d = new Date()
	let year = d.getFullYear()
	return `1/1/${year}`
}

const getFirstOfPreviousYear = () => {
	let d = new Date()
	let previousYear = d.getFullYear() - 1
	return `1/1/${previousYear}`
}

const getLastOfPreviousYear = () => {
	let d = new Date()
	let previousYear = d.getFullYear() - 1
	return `12/31/${previousYear}`
}

const getFirstOfQuarter = () => {
	let d = new Date()
	let year = d.getFullYear()
	let month = d.getMonth()
	if (month === 0 || month === 1 || month === 2) {
		return `01/01/${year}`
	} else if (month === 3 || month === 4 || month === 5) {
		return `04/01/${year}`
	} else if (month === 6 || month === 7 || month === 8) {
		return `07/01/${year}`
	} else {
		return `10/01/${year}`
	}
}
const getLastOfQuarter = () => {
	let d = new Date()
	let year = d.getFullYear()
	let month = d.getMonth()
	if (month === 0 || month === 1 || month === 2) {
		return `03/31/${year}`
	} else if (month === 3 || month === 4 || month === 5) {
		return `06/30/${year}`
	} else if (month === 6 || month === 7 || month === 8) {
		return `09/30/${year}`
	} else {
		return `12/31/${year}`
	}
}

const getFirstOfPreviousQuarter = () => {
	let d = new Date()
	let year = d.getFullYear()
	let previousYear = d.getFullYear() - 1
	let month = d.getMonth()
	if (month === 0 || month === 1 || month === 2) {
		return `10/01/${previousYear}`
	} else if (month === 3 || month === 4 || month === 5) {
		return `01/01/${year}`
	} else if (month === 6 || month === 7 || month === 8) {
		return `04/01/${year}`
	} else {
		return `07/01/${year}`
	}
}

const getLastOfPreviousQuarter = () => {
	let d = new Date()
	let year = d.getFullYear()
	let previousYear = d.getFullYear() - 1
	let month = d.getMonth()
	if (month === 0 || month === 1 || month === 2) {
		return `12/31/${previousYear}`
	} else if (month === 3 || month === 4 || month === 5) {
		return `03/30/${year}`
	} else if (month === 6 || month === 7 || month === 8) {
		return `07/30/${year}`
	} else {
		return `10/31/${year}`
	}
}

const getTrailing6Months = () => {
	let sixMonthDate = new Date(
		new Date().getFullYear(),
		new Date().getMonth() - 6,
		new Date().getDate()
	)
	return sixMonthDate.toLocaleDateString()
}

const getTrailing12Months = () => {
	let twelveMonthDate = new Date(
		new Date().getFullYear(),
		new Date().getMonth() - 12,
		new Date().getDate()
	)
	return twelveMonthDate.toLocaleDateString()
}

export {
	appendClassName,
	capitalize,
	newCheckForErrors,
	compareObjStrings,
	convertToPercent,
	formatDate,
	formatData,
	formatFileSize,
	getCurrentDate,
	getDarkThemeColor,
	getExtensionFromType,
	getLastWeekdayDate,
	getYTD,
	handleErrorMessage,
	intToCurrency,
	isValidDateRange,
	numberWithCommas,
	parseURL,
	sleep,
	states,
	slugify,
	groupBy,
	getFirstDayOfYear,
	getFirstOfPreviousYear,
	getLastOfPreviousYear,
	getFirstOfQuarter,
	getLastOfQuarter,
	getFirstOfPreviousQuarter,
	getLastOfPreviousQuarter,
	getTrailing6Months,
	getTrailing12Months,
}
