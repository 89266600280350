import React, { useEffect } from 'react'
import {
	TextInput,
	LogoBox,
	Button,
	Alert,
	LoadingAnimation,
} from '../shared/exports'
import { useAlerts, useAuthenticateUser, useAuth } from './AuthHooks'
import { Link, navigate } from '@reach/router'
import enterpriseObject from './enterpriseObject'
import './login-form.scss'

function Footer({ lenderUser }) {
	return (
		<div className='link-container'>
			{lenderUser ? null : (
				<Link className='link' to='/verify/resend'>
					Resend Verification Email
				</Link>
			)}
			<Link
				className='link'
				to='/password/send-request'
				data-testid='reset-password'
			>
				Reset Password
			</Link>
		</div>
	)
}

function LoginForm({ enterpriseId, location }) {
	const authenticateUser = useAuthenticateUser()
	const { alertObj, displayError, setAlertObj } = useAlerts()
	const { isAuthenticated, homepath } = useAuth()
	const [lenderUser, setLenderUser] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(false)
	const logo = enterpriseObject[location.host]
		? enterpriseObject[location.host].image
		: null

	useEffect(() => {
		let isLenderUser =
			location &&
			!location.href.includes('quantalytix') &&
			location.hostname !== 'localhost'
		if (isLenderUser) {
			localStorage.setItem('key', location.host)
			setLenderUser(true)
		} else if (enterpriseId) {
			localStorage.setItem('key', enterpriseId)
			setLenderUser(true)
		} else {
			localStorage.removeItem('key')
			setLenderUser(false)
		}
	}, [enterpriseId, location])

	if (isAuthenticated) {
		navigate(homepath || '/home')
		return null
	}

	const handleOnSubmit = e => {
		e.preventDefault()
		let email = e.target.email.value
		let password = e.target.password.value
		authenticateUser(email, password, setIsLoading)
	}

	return (
		<LogoBox
			className={lenderUser ? 'login-form lender' : 'login-form'}
			logo={logo}
			footer={<Footer lenderUser={lenderUser} />}
		>
			<Alert
				hidden={displayError ? false : true}
				onCloseAlert={() => setAlertObj({ ...alertObj, displayError: '' })}
				data-testid='loginform-alert'
			>
				{displayError}
			</Alert>
			<form className='form' onSubmit={handleOnSubmit} data-testid='logo-form'>
				<TextInput
					placeholder='Email'
					type='email'
					name='email'
					required={true}
					data-testid='login-email'
				/>
				<TextInput
					type='password'
					name='password'
					placeholder='Password'
					required={true}
					data-testid='login-password'
				/>
				<div className='btn-container'>
					<Button color='fill' type='submit' data-testid='login-btn'>
						{isLoading ? (
							<LoadingAnimation
								className='login-loading-animation'
								color='var(--text)'
								size='8px'
							/>
						) : (
							'Login'
						)}
					</Button>
					{lenderUser ? null : (
						<Link to='/register/email-check'>
							<Button data-testid='register'>
								New Here? &nbsp;Signup instead.
							</Button>
						</Link>
					)}
				</div>
			</form>
		</LogoBox>
	)
}

export default LoginForm
