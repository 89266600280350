import React from 'react'
import { appendClassName } from '../utils'
import './icon-button.scss'

export default function IconButton({ icon, className, ...otherProps }) {
	return (
		<button
			className={appendClassName('icon-button', className)}
			{...otherProps}
		>
			{icon}
		</button>
	)
}
