import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './shadowbox.scss'
import { LoadingAnimation } from '../exports'

class ShadowBox extends Component {
	constructor(props) {
		super(props)

		this.rootRef = React.createRef()

		this.state = {
			boxHeight: 0,
			boxWidth: 0,
			resizeTimeout: null,
		}

		this.updateDimensions = this.updateDimensions.bind(this)
	}

	initDimensions() {
		this.setState({
			boxHeight: this.getChildHeight(),
			boxWidth: this.getChildWidth(),
			resizeTimeout: null,
		})

		this.updateDimensions()
	}

	// see: https://developer.mozilla.org/en-US/docs/Web/Events/resize
	updateDimensions() {
		if (!this.state.resizeTimeout) {
			this.setState({
				resizeTimeout: setTimeout(
					() =>
						this.setState({
							boxHeight: this.getChildHeight(),
							boxWidth: this.getChildWidth(),
							resizeTimeout: null,
						}),
					200
				),
			})
		}
	}

	getChildHeight() {
		if (this.rootRef.current) {
			let el = this.rootRef.current
			let c = window.getComputedStyle(el.childNodes[0])

			let height = parseFloat(c.height) || el.offsetHeight

			let margin = parseFloat(c.marginTop) + parseFloat(c.marginBottom)
			let padding = parseFloat(c.paddingTop) + parseFloat(c.paddingBottom)
			let border =
				parseFloat(c.borderTopWidth) + parseFloat(c.borderBottomWidth)

			let result = height + margin + padding + border
			//console.log(`height: ${height} margin: ${margin} padding: ${padding} border: ${border}`);

			return result //this.rootRef.current.offsetHeight;
		}
		return 0
	}

	getChildWidth() {
		if (this.rootRef.current) {
			let el = this.rootRef.current //|| window.getComputedStyle(element);
			let c = window.getComputedStyle(el.childNodes[0])

			let width = parseFloat(c.width) || el.offsetWidth

			let margin = parseFloat(c.marginLeft) + parseFloat(c.marginRight)
			let padding = parseFloat(c.paddingLeft) + parseFloat(c.paddingRight)
			let border =
				parseFloat(c.borderLeftWidth) + parseFloat(c.borderRightWidth)

			return width + margin + padding + border
		}
		return 0
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions)
		this.initDimensions()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions)
	}

	generateClassName() {
		const { visible, className } = this.props
		let css = 'container'
		if (visible) {
			css += ' visible'
		}
		if (className) {
			css += ` ${className}`
		}
		return css
	}

	chooseModal() {
		switch (this.props.modal) {
			case 'loading':
				return <LoadingAnimation />
			default:
				return this.props.modal
		}
	}

	render() {
		const divStyle = {
			height: this.state.boxHeight,
			top: -1 * this.state.boxHeight,
			width: this.state.boxWidth,
		}
		return (
			<span className='shadowbox'>
				<div className='children' ref={this.rootRef}>
					{this.props.children}
				</div>
				<div style={divStyle} className={this.generateClassName()}>
					{this.chooseModal()}
				</div>
			</span>
		)
	}
}

ShadowBox.propTypes = {
	children: PropTypes.element.isRequired,
}

export default ShadowBox

/*
https://stackoverflow.com/questions/19014250/reactjs-rerender-on-browser-resize
*/
