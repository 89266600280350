import React from 'react'
import ThemeProvider from 'ThemeProvider'
import NavigationProvider from 'navigation/NavigationProvider'
import { useAuth } from 'authentication/AuthHooks'
import { Location } from '@reach/router'
import AuthenticatedApp from 'authentication/AuthenticatedApp'
import UnauthenticatedApp from 'UnauthenticatedApp'
import { LoadingAnimation } from 'shared/exports'
import './app.scss'

import './shared/theme/q-ui.scss'
import './shared/theme/q-helpers.scss'
import './shared/theme/q-icons.scss'
// to test out shared component library in node_modules
// import {Button, Alert, TextInput} from 'zeeCompTest'

export default function App(params) {
	const { isAuthenticated, isLoading } = useAuth()

	return (
		<ThemeProvider isAuthenticated={isAuthenticated}>
			{!isLoading ? (
				<>
					{isAuthenticated ? (
						<Location>
							{({ location }) => (
								<NavigationProvider location={location}>
									<AuthenticatedApp location={location} />
								</NavigationProvider>
							)}
						</Location>
					) : (
						<UnauthenticatedApp />
					)}
				</>
			) : (
				<div className='app-loading'>
					<LoadingAnimation />
				</div>
			)}
		</ThemeProvider>
	)
}
