import React, { useState } from 'react'
import { themes, useTheme } from './shared/exports'
import { useAuth } from './authentication/AuthHooks'
import FetchService from './services/fetchService'

const ThemeContext = React.createContext()

export default function ThemeProvider({ children, isAuthenticated }) {
	const { userObj, checkForErrors } = useAuth()
	const [theme, setTheme] = useState('dark')

	useTheme(themes[theme])

	React.useEffect(() => {
		if (userObj?.user?.settingsJson?.theme) {
			setTheme(userObj.user.settingsJson.theme)
		}
		if (!isAuthenticated) {
			setTheme('dark')
		}
	}, [userObj, isAuthenticated])

	const updateTheme = async theme => {
		// console.error('User theme cannot be updated. Endpoint no longer exists.')
		//endpoint no longer exists
		let body = {
			settings: { ...userObj.user.settingsJson, theme },
		}
		let resp = await FetchService.put('/user/settings', body)
		const success = checkForErrors(resp)
		if (success) {
			setTheme(theme)
		}
	}

	const context = {
		theme,
		setTheme,
		updateTheme,
	}

	return (
		<ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
	)
}

export const useUpdateTheme = () => {
	const context = React.useContext(ThemeContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}
