import { navigate } from '@reach/router'
import { getExtensionFromType } from 'shared/utils'

const devApiUrl = 'https://dev-quantalytix.azurewebsites.net/api'

function client(
	endpoint,
	customURL = devApiUrl,
	{ body, ...customConfig } = {}
) {
	const apiUrl =
		process.env.NODE_ENV === 'development' || 'test' ? customURL : '/api'
	const headers = { 'Content-Type': body ? 'application/json' : 'html/text' }
	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	}

	if (body) {
		config.body = JSON.stringify(body)
	}
	return window.fetch(`${apiUrl}${endpoint}`, config).then(async response => {
		// if (response.status === 401) {
		// 	navigate('/login')
		// 	return Promise.reject({ message: 'Please re-authenticate.' })
		// }
		const text = await response.text()
		try {
			const data = await JSON.parse(text)
			if (response.ok) {
				return data
			} else {
				return Promise.reject(data)
			}
		} catch (parsingError) {
			if (response.ok) {
				return text
			} else {
				return Promise.reject(text)
			}
		}
	})
}

const downloadOrOpenFile = (filename, extension, url) => {
	if (extension === 'csv') {
		const tempLink = document.createElement('a')
		tempLink.style.display = 'none'
		tempLink.href = url
		tempLink.setAttribute('download', `${filename}.${extension}`)
		document.body.appendChild(tempLink)
		if (window.Cypress) return // note: added this so the temp link isn't clicked and removed during test.
		tempLink.click()
		document.body.removeChild(tempLink)
	} else {
		window.open(url, '_blank')
	}
}

async function exportServerData(
	url,
	filename,
	{ body = null, ...customConfig } = {}
) {
	return window
		.fetch(url, { method: body ? 'POST' : 'GET', ...customConfig })
		.then(async response => {
			if (response.status === 401) {
				navigate('/login')
				return Promise.reject({ message: 'Please re-authenticate.' })
			}
			if (response.ok) {
				try {
					let blob = await response.blob()
					let extension = getExtensionFromType(blob.type)
					let url = URL.createObjectURL(blob)
					downloadOrOpenFile(filename, extension, url)
					URL.revokeObjectURL(blob)
					return { success: 'The call was successful' }
				} catch (error) {
					return Promise.reject({
						error: error,
						message: 'There was an error downloading the file.',
					})
				}
			} else {
				return Promise.reject({
					error: 'server error',
					message: 'There was an error downloading the file.',
				})
			}
		})
}

function query(endpoint, body) {
	return client(`${endpoint}`, devApiUrl, {
		body: body,
	})
}
function create(endpoint, body) {
	return client(`${endpoint}`, devApiUrl, {
		body: body,
	})
}
function get(endpoint) {
	return client(`${endpoint}`, devApiUrl)
}
function update(endpoint, body) {
	return client(`${endpoint}`, devApiUrl, {
		method: 'PUT',
		body: body,
	})
}
function remove(endpoint) {
	return client(`${endpoint}`, devApiUrl, { method: 'DELETE' })
}

function download(endpoint, filename, body) {
	return exportServerData(endpoint + devApiUrl, filename, body)
}

export {
	exportServerData,
	download,
	create,
	get,
	remove,
	update,
	query,
	client,
}
