import React from 'react'
import './options-indicator.scss'

export default function OptionsIndicator({ className }) {
	return (
		<div
			className={
				className ? `options-indicator ${className}` : 'options-indicator'
			}
		>
			<span className='dot'></span>
			<span className='dot'></span>
			<span className='dot'></span>
		</div>
	)
}
