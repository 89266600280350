import { useContext, useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { FetchServiceWithApi } from 'services/fetchService'
import { parseURL } from '../shared/utils'
import { create } from 'services/api-client'

// import { AuthContext } from './AuthProvider'
import { AuthContext } from './legacyAuthentication/LegacyAuthProvider'

export function useAuth() {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}

export function useAuthenticateUser() {
	const authService = new FetchServiceWithApi('https://app.quantalytix.com')
	const { checkForErrors, authSuccess, logout } = useAuth()

	const legacyAuthenticateUser = async (email, password, loadingFn) => {
		loadingFn(true)
		let enterpriseKey = localStorage.getItem('key')
		let params = {
			email: email.toLowerCase(),
			password: password,
			enterpriseKey,
		}
		let resp = await authService.post('/login', params)
		let customMessage = "I'm sorry! I am not able to log you in right now."
		let successResp = checkForErrors(resp, customMessage)
		if (successResp) {
			authSuccess(successResp)
			navigate(`/${successResp.user.settings.homepath}`)
		} else {
			logout()
		}
		loadingFn(false)
	}
	return legacyAuthenticateUser
}

export function useSendPasswordReset() {
	const authService = new FetchServiceWithApi('https://app.quantalytix.com')

	const { checkForErrors } = useAuth()

	const sendPasswordReset = async email => {
		let enterpriseKey = localStorage.getItem('key')
		let resp = await authService.post('/login/recover', {
			email: email,
			enterpriseKey,
		})
		let successResp = checkForErrors(resp)
		if (successResp) {
			navigate('/password/email-sent')
		}
	}
	return sendPasswordReset
}

export function useEmailCheck() {
	const authService = new FetchServiceWithApi('https://app.quantalytix.com')

	const { setUserEmail, checkForErrors } = useAuth()
	const [canSubmit, setCanSubmit] = useState(false)

	const emailCheck = async email => {
		let resp = await authService.post('/registration/check', {
			email: email.toLowerCase(),
		})
		let successResp = checkForErrors(resp)
		if (successResp) {
			setUserEmail(email)
			setCanSubmit(true)
		} else {
			setUserEmail('')
			setCanSubmit(false)
		}
	}
	return { emailCheck, canSubmit }
}

export function useCheckToken() {
	const authService = new FetchServiceWithApi('https://app.quantalytix.com')

	const { setServerAlert } = useAuth()

	const checkToken = async () => {
		let body = parseURL()
		if (body.token !== 'undefined') {
			let resp = await authService.post('/registration/confirm', body)
			if (resp.failed) {
				setServerAlert()
			} else if (resp.error) {
				if (resp.error.code === 11) {
					navigate('/login')
				} else {
					navigate('/verify/error')
				}
			} else {
				navigate('/verify/confirmed')
			}
		}
	}

	return checkToken
}

export function useResendVerification() {
	const authService = new FetchServiceWithApi('https://app.quantalytix.com')

	const { checkForErrors } = useAuth()

	const resendVerification = async email => {
		let key = localStorage.getItem('key')
		let body = {
			userType: key ? 'lender' : 'enterprise',
			email,
		}
		let resp = await authService.post('/registration/resend', body)
		let successResp = checkForErrors(resp)
		if (successResp) {
			navigate('/register/email-sent')
		}
	}

	return resendVerification
}

export function useRegisterAccount() {
	const authService = new FetchServiceWithApi('https://app.quantalytix.com')

	const { checkForErrors, userEmail } = useAuth()

	const registerAccount = async formInputs => {
		let key = localStorage.getItem('key')
		let body = {
			...formInputs,
			email: userEmail,
			userType: key ? 'lender' : 'enterprise',
			enterpriseKey: key,
		}
		let resp = await authService.post('/registration', body)
		let successResp = checkForErrors(resp)
		if (successResp) {
			navigate('email-sent')
		}
	}
	return registerAccount
}

export function useAuthorization(permissionString) {
	const { userObj } = useAuth()
	const [isAuthorized, setIsAuthorized] = useState(false)

	useEffect(() => {
		if (userObj) {
			if (permissionString) {
				let hasPermission = userObj.claims.find(p => p === permissionString)
				setIsAuthorized(hasPermission ? true : false)
			}
		}
	}, [userObj])

	return isAuthorized
}

export function useLenderUser() {
	const { userObj, lenderName } = useAuth()

	return { portalLenderId: userObj.user.lenderId, companyName: lenderName }
}

// All of these should probably be replaced by useAuth()
export function useUserPermissions() {
	const { userObj } = useAuth()
	const [permissions, setPermissions] = useState(null)

	useEffect(() => {
		if (userObj) {
			setPermissions(userObj.permissions)
		}
	}, [userObj])

	return permissions
}

export function useAlerts() {
	const { setAlertObj, alertObj } = useAuth()

	return {
		alertObj,
		serverError: alertObj.serverError,
		displayError: alertObj.displayError,
		setAlertObj,
	}
}

// NEW HOOKS for Updated Authentication

// export function useAuthenticateUser() {
// 	const { setIsLoading, authSuccess, logout, setAlerts } = useAuth()

// 	const authenticateUser = async e => {
// 		e.preventDefault()
// 		setIsLoading(true)
// 		let params = {
// 			email: e.target.email.value.toLowerCase(),
// 			passwordHash: e.target.password.value,
// 		}
// 		create('/session', params).then(
// 			session => {
// 				setIsLoading(false)
// 				authSuccess(session)
// 				navigate(`/${session.user.settingsJson?.homepath || 'home'}`)
// 			},
// 			error => {
// 				setIsLoading(false)
// 				let customMessage = "I'm sorry! I am not able to log you in right now."
// 				setAlerts(error, customMessage)
// 				logout()
// 			}
// 		)
// 	}
// 	return authenticateUser
// }
