import React from 'react'
import './sidebar-icon.scss'

export default function SidebarIcon({
	module,
	currentModule,
	icon,
	onClick,
	...otherProps
}) {
	return (
		<div
			name={module.moduleKey.replace('Quantalytix.', '').replace('.', '-')}
			className={
				currentModule
					? 'sidebar-icon-container selected'
					: 'sidebar-icon-container'
			}
			data-testid={module.moduleKey}
			id={module.moduleKey}
			onClick={onClick}
			{...otherProps}
		>
			{icon}
		</div>
	)
}
