import React, { useEffect } from 'react'

export default function AppVersion() {
	const version = '21.02.10'

	useEffect(() => {
		const checkVersion = async () => {
			let userVersion = localStorage.getItem('version')
			if (!userVersion) {
				updateAndRefresh(version)
			} else {
				if (userVersion !== version) {
					updateAndRefresh(version)
				}
			}
		}
		checkVersion()
		setInterval(checkVersion, 300000)
		return function cleanup() {
			clearInterval()
		}
	}, [])

	const updateAndRefresh = version => {
		localStorage.setItem('version', version)
		window.location.reload()
	}
	return <div className='version m-r-1'>{localStorage.getItem('version')}</div>
}
