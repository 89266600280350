const addSubscriptions = (modules, isLender) => {
	let subscriptions = modules.map(mod => mod.moduleKey)
	if (isLender) {
		subscriptions.push('Quantalytix.Lender.Admin')
	}
	return subscriptions
}

const portUserObj = resp => {
	let userObj = {
		sessionId: `${resp.userId}-${resp.lastName}-${Math.floor(
			Math.random() * 101
		)}`,
		user: {
			userGuid: resp.userId,
			// accountTypeId: null,
			emailAddress: resp.user.email,
			// isEmailVerified: null,
			// isLocked: null,
			firstName: resp.user.firstName,
			lastName: resp.user.lastName,
			failedLoginCount: 0,
			settingsJson: resp.settings,
			// lastLoginDate: null,
		},
		enterprise: {
			enterpriseId: resp.enterprise.enterpriseId,
			enterpriseGuid: resp.enterprise.enterpriseId,
			companyName: resp.enterprise.companyName,
		},
		subscriptions: addSubscriptions(
			resp.enterprise.modules,
			resp.user.lenderId
		),
		claims: resp.permissions,
		menu: resp.environment,
	}
	return userObj
}

export { portUserObj }
