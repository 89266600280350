import React from 'react'
import { appendClassName } from '../utils'
import { Button } from '../exports'
import './add-button.scss'

export default function AddButton({
	className,
	children,
	size,
	...otherProps
}) {
	return (
		<Button className={appendClassName('add-btn', className)} {...otherProps}>
			<div className='display-container'>
				<span style={{ fontSize: `var(--font-size-${size})` }}>&#43;</span>
				{children}
			</div>
		</Button>
	)
}

AddButton.defaultProps = {
	size: 'default',
}
