import React from 'react'
import { Button, DateRangePicker, MinMaxSlider } from 'shared/exports'
import './date-range-slider.scss'

export default function DateRangeSlider({
	minDate,
	maxDate,
	onSubmit,
	defaults,
}) {
	const [minMonth, minDay, minYear] = minDate.split('/')
	const [maxMonth, maxDay, maxYear] = maxDate.split('/')
	const [
		defaultStartMonth,
		defaultStartDay,
		defaultStartYear,
	] = defaults.start.split('/')
	const [defaultEndMonth, defaultEndDay, defaultEndYear] = defaults.end.split(
		'/'
	)
	const domain = [parseInt(minYear), parseInt(maxYear)]

	const [sliderValues, setSliderValues] = React.useState([
		defaultStartYear,
		defaultEndYear,
	])
	const [inputs, setInputs] = React.useState({
		start: `${defaultStartYear}-${defaultStartMonth}-${defaultStartDay}`,
		end: `${defaultEndYear}-${defaultEndMonth}-${defaultEndDay}`,
	})

	const convertDateFormat = date => {
		let dateArr = date.split('-')
		let year = dateArr[0]
		let month = dateArr[1]
		let day = dateArr[2]
		return [month, day, year]
	}

	const handleInputSelection = e => {
		e.preventDefault()
		let [startMonth, startDay, startYear] = convertDateFormat(inputs.start)
		let [endMonth, endDay, endYear] = convertDateFormat(inputs.end)
		onSubmit({
			selectedStartDate: [startMonth, startDay, startYear].join('/'),
			selectedEndDate: [endMonth, endDay, endYear].join('/'),
		})
		setSliderValues([startYear, endYear])
	}

	const handleSliderSelection = ([start, end]) => {
		let startArr = inputs.start.split('-')
		let endArr = inputs.end.split('-')
		let fullStart = `${start}-${startArr[1]}-${startArr[2]}`
		let fullEnd = `${end}-${endArr[1]}-${endArr[2]}`
		setInputs({ start: fullStart, end: fullEnd })
	}

	const handleOnInputChange = ({ name, value }) => {
		setInputs({
			...inputs,
			[name]: value,
		})
	}

	return (
		<form onSubmit={handleInputSelection} className='date-range-slider'>
			<fieldset>
				Date Range
				<DateRangePicker
					minDate={`${minYear}-${minMonth}-${minDay}`}
					maxDate={`${maxYear}-${maxMonth}-${maxDay}`}
					values={inputs}
					onChange={handleOnInputChange}
				/>
				<MinMaxSlider
					domain={domain}
					values={sliderValues}
					onChange={handleSliderSelection}
				/>
			</fieldset>
			<Button className='slider-submit-btn' type='submit'>
				Set Date Range
			</Button>
		</form>
	)
}
