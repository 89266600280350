import React from 'react'
import './data-filter-layout.scss'

export default function DataFilterLayout({ className, children }) {
	return (
		<div
			className={
				className ? `data-filter-layout ${className}` : 'data-filter-layout'
			}
		>
			{children}
		</div>
	)
}
