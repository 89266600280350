import { ApplicationInsights } from '@microsoft/applicationinsights-web'

// const customPlugin = new CustomPlugin();
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: '0244b3a5-5a47-4e85-8aa3-3bca9432fa70',
		// extensions: [customPlugin],
		/* ...Other Configuration Options... */
	},
})

export default appInsights
