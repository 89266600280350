import React from 'react'
import './pill.scss'

const Pill = ({ onCancel, label }) => {
	return (
		<div className='pill'>
			<p className='label'>{label}</p>
			<button className='exit-btn' onClick={onCancel}>
				<i className='icon-cancel'></i>
			</button>
		</div>
	)
}

export default Pill
