import React, { Suspense } from 'react'
import QHeader from 'navigation/QHeader'
import QSidebar from 'navigation/QSidebar'
import AuthenticatedRoutes from 'authentication/AuthenticatedRoutes'
import { useAlerts } from 'authentication/AuthHooks'
import { useNavigation } from 'navigation/NavigationProvider'
import {
	Loader,
	ScreenAlertContainer,
	Alert,
	ErrorBoundary,
} from 'shared/exports'
import getModuleObj from 'modules/moduleObj'
import { Router, navigate } from '@reach/router'

export default function AuthentictedApp({ className, location }) {
	const { alertObj, serverError, setAlertObj } = useAlerts()
	const { moduleKeys, isExpanded } = useNavigation()
	const [subscribedModules, setSubscribedModules] = React.useState([])

	React.useEffect(() => {
		const selectModules = () => {
			let subscribed = moduleKeys.map(key => getModuleObj(key).component)
			setSubscribedModules(subscribed)
		}
		selectModules()
	}, [moduleKeys])

	const getMainClassName = () => {
		let css = 'main-container'
		if (isExpanded) {
			css += ' push-content'
		}
		return css
	}

	if (location.pathname === '/') {
		navigate('/home')
	}

	return (
		<div className='app-layout'>
			<QHeader />
			<QSidebar />
			<ErrorBoundary>
				<div className={getMainClassName()}>
					<ScreenAlertContainer>
						<Alert
							hidden={serverError ? false : true}
							type='danger'
							dismissible={true}
							onCloseAlert={() => setAlertObj({ ...alertObj, serverError: '' })}
						>
							{serverError}
						</Alert>
					</ScreenAlertContainer>
					<Suspense fallback={<Loader isLoading={true} />}>
						<AuthenticatedRoutes subscribedModules={subscribedModules} />
					</Suspense>
				</div>
			</ErrorBoundary>
		</div>
	)
}
