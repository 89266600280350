import React from 'react'
import './landing-page.scss'
import qlogo from '../../assets/images/logo_black_bg.svg'

export default function LandingPage() {
	return (
		<div className='landing-page-container'>
			<h3 className='landing-page-statement'>Welcome to</h3>
			<img
				className='quantalytixs-logo'
				src={qlogo}
				alt='Quantalytix Logo'
				height='25px'
			/>
		</div>
	)
}
