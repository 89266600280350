import React from 'react'
import './arrow-indicator.scss'

export default function ArrowIndicator({
	className,
	color,
	direction,
	size,
	...otherProps
}) {
	const determineSize = () => {
		switch (size) {
			case 'large':
				return '4px'
			case 'xl':
				return '5px'
			default:
				return '3px'
		}
	}

	return (
		<div
			className={className ? `arrow-indicator ${className}` : 'arrow-indicator'}
			{...otherProps}
			aria-label={otherProps['aria-label'] ?? `${direction} arrow`}
		>
			<i
				style={{ borderColor: color, padding: determineSize() }}
				className={`arrow-${direction}`}
			></i>
		</div>
	)
}
