import React from 'react'
import Tooltip from '@reach/tooltip'
import '@reach/tooltip/styles.css'
import './reach-tooltip.scss'

export default function ReachTooltip({ children, text, ...otherProps }) {
	if (text) {
		return (
			<Tooltip className='reach-tooltip' label={text}>
				{children ? (
					children
				) : (
					<button className='tooltip-question-button'>
						<i className='icon-question-circle-regular'></i>
					</button>
				)}
			</Tooltip>
		)
	}
	return null
}
