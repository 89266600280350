import React from 'react'
import LoadingAnimation from './LoadingAnimation'
import './full-screen-loading.scss'

export default function FullScreenLoading() {
	return (
		<div className='full-screen-loading'>
			<LoadingAnimation />
		</div>
	)
}
