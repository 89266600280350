import { getExtensionFromType, getCurrentDate } from '../shared/utils'

class FetchServiceWithApi {
	constructor(api) {
		let env = document.location.href
		if (env.includes('local') || env.includes('dev')) {
			this.baseApi = api + '/api'
		} else {
			this.baseApi = '/api'
		}
		this.fetchParams = {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
			credentials: 'include',
		}
	}

	authorized(resp) {
		return resp.status !== 401
	}

	post(endpoint, body = null) {
		const safePost = this.catchErrorFromServer(this.callToPost)
		return safePost(endpoint, body)
	}

	get(endpoint) {
		const safeGet = this.catchErrorFromServer(this.callToGet)
		return safeGet(endpoint)
	}

	put(endpoint, body) {
		const safePut = this.catchErrorFromServer(this.callToPut)
		return safePut(endpoint, body)
	}

	delete(endpoint, body) {
		const safeDelete = this.catchErrorFromServer(this.callToDelete)
		return safeDelete(endpoint, body)
	}

	upload(endpoint, body) {
		const safeUpload = this.catchErrorFromServer(this.callToUpload)
		return safeUpload(endpoint, body)
	}

	export(endpoint, body, fileName = 'export') {
		const safeExport = this.catchErrorFromServer(this.callToExport)
		return safeExport(endpoint, body, fileName)
	}

	deleteCookieAndRedirectToLogin() {
		let key = localStorage.getItem('key')
		let path = document.location.href
		let env = document.location.pathname
		let domain = path.replace(env, '')
		this.delete('/login')
		let onLoginScreen = env.includes('login')
		if (!onLoginScreen) {
			// redirect to login screen
			window.location.href = key ? `${domain}/portal/${key}` : `${domain}/login`
		}
	}

	handleHttpError(response) {
		if (!response.ok) {
			if (!this.authorized(response)) {
				this.deleteCookieAndRedirectToLogin()
			}
			throw Error(response.status)
		}
		return response
	}

	// WILL ALSO CATCH PARSING ERRORS
	catchErrorFromServer = fn => (...params) =>
		fn(...params).catch(error => {
			const endpoint = params[0]
			console.error(`Unsuccessful call to ${endpoint}: ${error}`)
			return { failed: error.message }
		})

	determineContentType = async resp => {
		const contentType = resp.headers.get('content-type')
		if (contentType && contentType.includes('json')) {
			const json = await resp.json()
			return json
		} else {
			return resp
		}
	}

	callToGet = async endpoint => {
		const resp = await fetch(this.baseApi + endpoint, this.fetchParams)
		this.handleHttpError(resp)
		console.log(`successful call to GET ${endpoint}`)
		return await this.determineContentType(resp)
	}

	callToPost = async (endpoint, body) => {
		let resp = await fetch(this.baseApi + endpoint, {
			...this.fetchParams,
			method: 'POST',
			body: JSON.stringify(body),
		})
		this.handleHttpError(resp)
		console.log(`successful call to POST ${endpoint}`)
		return await this.determineContentType(resp)
	}

	callToDelete = async (endpoint, body = null) => {
		const resp = await fetch(this.baseApi + endpoint, {
			...this.fetchParams,
			method: 'DELETE',
			body: JSON.stringify(body),
		})
		this.handleHttpError(resp)
		console.log(`successful call to DELETE ${endpoint}`)
		return await this.determineContentType(resp)
	}

	callToPut = async (endpoint, body, params) => {
		const resp = await fetch(this.baseApi + endpoint, {
			...this.fetchParams,
			method: 'PUT',
			body: JSON.stringify(body),
		})
		this.handleHttpError(resp)
		console.log(`successful call to PUT ${endpoint}`)
		return await this.determineContentType(resp)
	}

	callToUpload = async (endpoint, body) => {
		const resp = await fetch(this.baseApi + endpoint, {
			method: 'POST',
			credentials: 'include',
			body: body,
		})
		this.handleHttpError(resp)
		console.log(`successful call to POST ${endpoint}`)
		return await this.determineContentType(resp)
	}

	callToExport = async (endpoint, body, fileName = 'export') => {
		let resp = body ? await this.post(endpoint, body) : await this.get(endpoint)
		if (resp.ok) {
			let blob = await resp.blob()
			let extension = getExtensionFromType(blob.type)
			let url = URL.createObjectURL(blob)
			let dateString = getCurrentDate()
			if (extension === 'csv') {
				const tempLink = document.createElement('a')
				tempLink.style.display = 'none'
				tempLink.href = url
				tempLink.setAttribute(
					'download',
					`${fileName}_${dateString}.${extension}`
				)
				document.body.appendChild(tempLink)
				if (window.Cypress) return // note: added this so the temp link isn't clicked and removed during test.
				tempLink.click()
				document.body.removeChild(tempLink)
			} else {
				window.open(url, '_blank')
			}
		}
		return resp
	}
}

const FetchService = new FetchServiceWithApi(
	'https://dev-quantalytix.azurewebsites.net'
)
export default FetchService

export { FetchServiceWithApi }
