/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Router } from '@reach/router'
import { useAuth } from './AuthHooks'
import { NotFound } from '../shared/exports'
import LandingPage from '../modules/LandingPage/LandingPage'

export default function AuthenticatedRoutes({ subscribedModules }) {
	const { isLoading, isAuthenticated } = useAuth()

	if (!isLoading && isAuthenticated) {
		return (
			<Router>
				{subscribedModules}
				<LandingPage path='home' />
				<NotFound default />
			</Router>
		)
	}
	return null
}
