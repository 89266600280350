import React, { useState } from 'react'
import { TextInput } from '../exports'
import { useAsyncDebounce } from 'react-table'
import './GlobalTableFilter.scss'

export default function GlobalTableFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	placeholder,
}) {
	const count = preGlobalFilteredRows.length
	const [value, setValue] = useState(globalFilter)
	const onChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined)
	}, 200)

	return (
		<TextInput
			className='global-table-filter'
			value={value || ''}
			onChange={e => {
				setValue(e.target.value)
				onChange(e.target.value)
			}}
			placeholder={placeholder || `Search ${count} rows...`}
		/>
	)
}
