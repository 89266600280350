import React from 'react'
import Select, { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'
import { getDarkThemeColor } from '../utils'
import { themes } from '../exports'

QSelect.propTypes = {
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	isMulti: PropTypes.bool,
	isSearchable: PropTypes.bool,
	placeholder: PropTypes.string,
	defaultInputValue: PropTypes.string,
	customStyles: PropTypes.object,
	staticDisplay: PropTypes.string,
}

// To create grouped options, options prop should be structured like this:
// const options = [
//   { label: "A root option", value: "value_3" },
//   {
//     label: "Group 1",
//     options: [
//       { label: "option 1", value: "value_1" },
//       { label: "option 2", value: "value_2" }
//     ]
//   },
//   {
//     label: "Group 2",
//     options: [
//       { label: "option 1", value: "value_1" },
//       { label: "option 2", value: "value_2" }
//     ]
//   },
// ];

const animatedComponents = makeAnimated()

export default function QSelect({
	customColors,
	keepDarkTheme,
	noHoverEffect,
	width,
	height,
	options,
	placeholder,
	isMulti,
	isSearchable,
	defaultInputValue,
	customStyles,
	customStyleFunctions,
	includeSeparator,
	...otherProps
}) {
	const colors = {
		outline: keepDarkTheme
			? themes.dark['border-color']
			: 'var(--border-color)',
		boxShadowDark: keepDarkTheme ? 'transparent' : 'var(--box-shadow-color)',
		background: keepDarkTheme ? themes.light['header-panel-bg'] : `var(--bg-2)`,
		backgroundDark: keepDarkTheme ? themes.dark['bg-2'] : 'var(--bg-2)',
		hover: keepDarkTheme
			? themes.dark['header-panel-hover']
			: 'var(--gray-hover)',
		border: keepDarkTheme
			? 'var(--header-btn-border-color)'
			: 'var(--border-color)',
		text: keepDarkTheme ? themes.dark.text : 'var(--text)',
		textMuted: keepDarkTheme ? themes.dark['text-muted'] : 'var(--text-muted)',
		...customColors,
	}

	const styles = {
		container: (provided, state) => ({
			...provided,
			outline: noHoverEffect
				? 'none'
				: state.isFocused || state.isSelected
				? `2px solid ${colors.outline}`
				: 'none',
			boxShadow:
				state.isFocused && noHoverEffect
					? `0 0 3px 3px ${colors.boxShadowDark}`
					: 'none',
			...customStyles.container,
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: 'transparent',
			borderColor: noHoverEffect
				? colors.background
				: state.isFocused
				? colors.border
				: colors.border,
			boxShadow: 'none',
			borderRadius: `1px`,
			minHeight: height || '44px',
			width: width,
			minWidth: width || '125px',
			color: colors.text,
			'&:hover': {
				borderColor: state.isMulti
					? colors.border
					: noHoverEffect
					? colors.backgrond
					: colors.border,
				boxShadow: `0 0 5px 5px ${colors.boxShadowDark}`,
				backgroundColor: noHoverEffect ? colors.background : colors.hover,
			},
			...customStyles.control,
		}),
		valueContainer: (provided, state) => ({
			...provided,
			padding: `var(--padding-default)`,
			color: colors.text,
			...customStyles.valueContainer,
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			alignSelf: state.isMulti ? 'start' : 'center',
			paddingTop: state.isMulti ? '7px' : '0',
		}),
		indicatorSeparator: (provided, state) => ({
			...provided,
			backgroundColor: colors.border,
			display: includeSeparator ? 'block' : 'none',
		}),
		input: (provided, state) => ({
			...provided,
			color: colors.text,
			...customStyles.input,
		}),
		clearIndicator: (provided, state) => ({
			...provided,
			color: colors.textMuted,
			'&:hover': {
				cursor: 'pointer',
				color: colors.text,
			},
			...customStyles.clearIndicator,
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
			color: colors.text,
			'&:hover': {
				color: colors.text,
				cursor: 'pointer',
			},
			...customStyles.dropdownIndicator,
		}),
		menu: (provided, state) => ({
			...provided,
			background: colors.background,
			zIndex: '4',
			...customStyles.menu,
		}),
		menuList: (provided, state) => ({
			...provided,
			boxShadow: `0 0 2px 2px ${colors.boxShadowDark}`,
			padding: '0',
			...customStyles.menuList,
		}),
		option: (provided, state) => ({
			...provided,
			background: state.isFocused ? colors.hover : colors.background,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			color: colors.text,
			'&:hover': {
				cursor: 'pointer',
			},
			'.item-icon': {
				display: 'flex',
				alignItems: 'center',
				i: {
					fontSize: 'larger',
					marginRight: '10px',
				},
			},
			...customStyles.option,
		}),
		multiValue: (provided, state) => ({
			...provided,
			backgroundColor: colors.backgroundDark,
			border: `1px solid ${colors.border}`,
			...customStyles.multiValue,
		}),
		multiValueLabel: (provided, state) => ({
			...provided,
			color: colors.text,
			...customStyles.multiValueLabel,
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: colors.text,
			...customStyles.singleValue,
		}),
		multiValueRemove: (provided, state) => ({
			...provided,
			color: colors.textMuted,
			'&:hover': {
				cursor: 'pointer',
				backgroundColor: colors.background,
				color: colors.text,
			},
			...customStyles.multiValueRemove,
		}),
	}

	const IconOption = ({ ...props }) => {
		return (
			<components.Option {...props}>
				{props.data.icon ? (
					<span className='item-icon'>
						<i className={props.data.icon}></i>
						{props.data.label}
					</span>
				) : (
					props.data.label
				)}
			</components.Option>
		)
	}

	return (
		<Select
			styles={{ ...styles, ...customStyleFunctions }}
			components={{ Option: IconOption, animatedComponents }}
			options={options}
			isMulti={isMulti || false}
			isSearchable={isSearchable || false}
			placeholder={placeholder || 'Select...'}
			defaultInputValue={defaultInputValue}
			hideSelectedOptions={false}
			{...otherProps}
		/>
	)
}

QSelect.defaultProps = {
	customStyles: {},
}
