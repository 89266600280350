import React, { useState } from 'react'
import './alert.scss'

export default function Alert({
	multipleAlerts,
	multipleAlertsMessage,
	dismissible,
	hidden,
	onCloseAlert,
	className,
	type,
	fill,
	icon,
	children,
	...otherProps
}) {
	const [showMore, setShowMore] = useState(false)
	const supportMessage =
		type === 'danger'
			? ' If you continue to experience problems, please email support@quantalytix.com.'
			: ''

	const renderCollapseButton = () => {
		return (
			<button
				className={`close ${fill ? '' : 'no-fill'}`}
				onClick={() => onCloseAlert()}
			>
				<span aria-hidden='true'>&times;</span>
			</button>
		)
	}

	const generateClassName = () => {
		let css = 'alert'
		if (type) css += ` alert-${type}`
		if (hidden) css += ' alert-hidden'
		if (!fill) css += ' no-fill'
		if (className) css += ` ${className}`
		return css
	}

	const handleMultipleAlerts = () => {
		if (multipleAlerts.length > 1) {
			return (
				<div className='multiple-alerts'>
					<span className='message'>
						{multipleAlertsMessage + supportMessage ||
							'There were multiple errors.' + supportMessage}
						<button
							className='show-more'
							onClick={() => setShowMore(!showMore)}
						>
							{showMore ? 'Hide' : 'Details'}
						</button>
					</span>
					{showMore
						? multipleAlerts.map((message, i) => <div key={i}>{message}</div>)
						: null}
				</div>
			)
		}
		return multipleAlerts[0] + supportMessage
	}

	return (
		<div
			className={generateClassName()}
			aria-hidden={hidden ? true : false}
			{...otherProps}
		>
			{icon ? <i className={icon} /> : null}
			<span className='content'>
				{multipleAlerts ? handleMultipleAlerts() : children + supportMessage}
			</span>
			{dismissible ? renderCollapseButton() : null}
		</div>
	)
}

Alert.defaultProps = {
	dismissible: true,
	onCloseAlert: () => {},
	fill: true,
}
