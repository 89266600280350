import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { FetchServiceWithApi } from 'services/fetchService'
import { handleErrorMessage } from 'shared/utils'
import appInsights from 'AppInsightsConfig'
import { portUserObj } from 'authentication/authUtils'

export const AuthContext = React.createContext()

export default function LegacyAuthProvider({ children }) {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [alertObj, setAlertObj] = useState({
		serverError: '',
		displayError: '',
	})
	const [serverError, setServerError] = useState('')
	const [userObj, setUserObj] = useState(null)
	const [userEmail, setUserEmail] = useState('')
	const [lenderName, setLenderName] = useState('')
	const authService = new FetchServiceWithApi('https://app.quantalytix.com')
	console.log(alertObj.displayError)

	useEffect(() => {
		const legacyRefreshAuth = async () => {
			const authService = new FetchServiceWithApi('https://app.quantalytix.com')
			await setIsLoading(true)

			let resp = await authService.get('/user/refresh')
			if (!resp.failed && !resp.error) {
				if (resp.user.lenderId) {
					localStorage.setItem('key', resp.enterprise.enterpriseKey)
				}
				await legacyAuthSuccess(resp)
			}
			setIsLoading(false)
		}
		legacyRefreshAuth()
	}, [])

	useEffect(() => {
		const checkForErrors = (resp, customMessage) => {
			if (resp.failed) {
				setServerAlert(customMessage)
			} else if (resp.error) {
				setDisplayAlert(handleErrorMessage(resp.error.code))
			} else {
				return resp
			}
		}
		const getCompanyName = async () => {
			if (userObj.user.lenderId) {
				const authService = new FetchServiceWithApi(
					'https://app.quantalytix.com'
				)

				let resp = await authService.get('/lender/contact')
				let successResp = checkForErrors(
					resp,
					'I am not able to access your information right now. Please refresh your browser.'
				)
				if (successResp) {
					setLenderName(successResp.companyName)
				}
			}
		}
		if (userObj) getCompanyName()
	}, [userObj])

	const legacyAuthSuccess = resp => {
		appInsights.setAuthenticatedUserContext(
			{
				authenticatedUserId: resp.user.userId,
				accountId: resp.enterprise.enterpriseKey,
			},
			true
		)
		setIsAuthenticated(true)
		let portedUserObj = portUserObj(resp)
		setUserObj(portedUserObj)
	}

	const checkForErrors = (resp, customMessage) => {
		if (resp.failed) {
			setServerAlert(customMessage)
		} else if (resp.error) {
			let errorMessage = handleErrorMessage(resp.error.code)
			setDisplayAlert(errorMessage)
		} else {
			return resp
		}
	}

	const setAlerts = (errorResp, customMessage) => {
		if (errorResp.errors) {
			let errorString = Object.values(errorResp.errors).join(' ')
			setDisplayAlert(errorString)
		} else {
			setServerAlert(customMessage)
		}
	}

	const setDisplayAlert = message => {
		setAlertObj({
			serverError: '',
			displayError: message,
		})
	}

	const setServerAlert = (
		customMessage = 'We are unable to process your request right now. Please try again later. '
	) => {
		setAlertObj({
			displayError: '',
			serverError: customMessage,
		})
	}

	const legacyLogout = () => {
		let key = localStorage.getItem('key')
		authService.delete('/login')
		setIsAuthenticated(false)
		navigate(key ? `/portal/${key}` : '/login')
		sessionStorage.clear()
		appInsights.clearAuthenticatedUserContext()
	}

	const resetPassword = async body => {
		let resp = await authService.post('/login/reset', body)
		let successResp = checkForErrors(resp)
		if (successResp) {
			navigate('/password/changed')
		}
	}

	const changePassword = async body => {
		let resp = await authService.put('/user/password', body)
		let successResp = checkForErrors(resp)
		if (successResp) {
			return true
		} else {
			return false
		}
	}

	const context = {
		isAuthenticated,
		isLoading,
		alertObj,
		userObj,
		userEmail,
		serverError,
		lenderName,
		// fn
		authSuccess: legacyAuthSuccess,
		changePassword,
		checkForErrors,
		resetPassword,
		setUserEmail,
		setIsAuthenticated,
		setServerError,
		setAlertObj,
		setServerAlert,
		setDisplayAlert,
		setIsLoading,
		logout: legacyLogout,
		setAlerts,
	}

	return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}
