import React, { useState } from 'react'
import { Button, ArrowIndicator } from '../exports'
import { appendClassName } from '../utils'
import './filter-dropdown.scss'

export default function FilterDropdown({
	keepDarkTheme,
	noArrow,
	width,
	menuWidth,
	height,
	className,
	buttonClassName,
	menuOpenText,
	menuClosedText,
	children,
	menuPosition,
	...otherProps
}) {
	const [showFilters, setShowFilters] = useState(false)

	const generateClassName = () => {
		let css = 'filter-btn '
		if (keepDarkTheme) {
			css += 'dark '
		}
		if (showFilters) {
			css += 'active '
		}
		if (buttonClassName) {
			css += buttonClassName
		}
		return css
	}

	return (
		<div
			className={appendClassName('filter-dropdown', className)}
			{...otherProps}
		>
			<Button
				style={{ minWidth: width || '140px' }}
				// id={keepDarkTheme ? "stay-dark-btn" : null}
				className={generateClassName()}
				onClick={() => setShowFilters(!showFilters)}
			>
				{showFilters
					? menuOpenText || 'Hide Filters'
					: menuClosedText || 'Show Filters'}
				{noArrow ? null : (
					<ArrowIndicator
						className='filter-btn-arrow'
						direction={showFilters ? 'up' : 'down'}
						color={keepDarkTheme ? 'var(--navigation-text)' : 'var(--text)'}
					/>
				)}
			</Button>
			<div
				className={showFilters ? 'filter-menu show' : 'filter-menu'}
				style={{
					...menuPosition,
					top: `calc(${height} + 8px)`,
					width: menuWidth || width,
				}}
			>
				{children}
			</div>
		</div>
	)
}

FilterDropdown.defaultProps = {
	height: '44px',
}
