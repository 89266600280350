import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { useAuth } from '../authentication/AuthHooks'
import menuArray from './menuObj'

const NavContext = React.createContext()

export default function NavigationProvider({ children, location }) {
	const { userObj } = useAuth()
	const [filteredModules, setFilteredModules] = useState({})
	const [moduleKeys, setModuleKeys] = useState([])
	const [moduleSelected, setModuleSelected] = useState(null)
	const [currentModule, setCurrentModule] = useState(null)
	const [pageSelected, setPageSelected] = useState({})
	const [isExpanded, setIsExpanded] = useState(false)
	const [isOverlaid, setIsOverlaid] = useState(false)

	useEffect(() => {
		if (userObj) {
			// let menuArray = userObj.menu
			let filtered = filterModules(menuArray)
			let keys = Object.keys(filtered)
			setFilteredModules(filtered)
			setModuleKeys(keys)
		}
	}, [userObj])

	useEffect(() => {
		let modules = Object.values(filteredModules)
		if (modules.length) {
			let mSelected
			let pSelected
			modules.forEach(m => {
				if (m.items) {
					m.items.forEach(i => {
						if (location.pathname.includes(i.route)) {
							mSelected = m
							pSelected = i
						}
					})
				}
			})
			setCurrentModule(mSelected)
			setPageSelected(pSelected)
			if (!moduleSelected) {
				setModuleSelected(currentModule)
			}
		}
	}, [currentModule, filteredModules, location.pathname, moduleSelected])

	const isModuleEnabled = module => {
		if (module.permission) {
			// return if enabled or visible
			// moduleItem dropdown will handle if visible
			let enabled = userObj.claims.includes(module.permission.enabled)
			let visible = userObj.claims.includes(module.permission.visible)
			return enabled || visible ? true : false
		}
		return true
	}

	const filterModules = menuArray => {
		let filtered = {}
		menuArray.forEach(module => {
			if (isModuleEnabled(module)) {
				let subscriptions = userObj.subscriptions
				if (subscriptions.includes(module.moduleKey)) {
					filtered[module.moduleKey] = module
				}
			}
		})
		return filtered
	}

	const handleExpanderClick = () => {
		if (isOverlaid) {
			setIsOverlaid(false)
		} else {
			setModuleSelected(currentModule)
		}
		setIsExpanded(!isExpanded)
	}

	const handleOverlayShow = e => {
		let key = e.currentTarget.id
		setModuleSelected(filteredModules[key])
		if (!isExpanded) {
			if (!isOverlaid) {
				setIsOverlaid(true)
			}
		}
	}

	const handleOverlayHide = e => {
		if (isOverlaid) {
			setIsOverlaid(false)
		}
	}

	const handleModuleIconClick = e => {
		let key = e.currentTarget.id
		if (moduleSelected === filteredModules[key]) {
			// if user clicks on the icon of the module they are currently in
			// the sidebar will open if it is closed, and close if it is open
			if (!isExpanded || isOverlaid) {
				setIsExpanded(true)
			} else {
				setIsExpanded(false)
			}
			// if the user clicks on a different icon
			// the sidebar menu will open or remain open, showing the new module menu
		} else {
			setModuleSelected(filteredModules[key])
			setIsExpanded(true)
		}
	}

	const handlePageClick = page => {
		setPageSelected(page)
		navigate(page.route)
	}

	const context = {
		filteredModules,
		moduleKeys,
		moduleSelected,
		pageSelected,
		isExpanded,
		setIsExpanded,
		currentModule,
		setCurrentModule,
		//
		handleExpanderClick,
		handleModuleIconClick,
		handlePageClick,
		isOverlaid,
		setIsOverlaid,
		handleOverlayShow,
		handleOverlayHide,
	}

	return <NavContext.Provider value={context}>{children}</NavContext.Provider>
}

export function useNavigation() {
	let context = React.useContext(NavContext)
	if (context === undefined) {
		// for MainContent logged out state
		context = {
			moduleKeys: [],
			isExpanded: false,
		}
		// throw new Error(`useNavigation must be used within a NavigationProvider`)
	}
	return context
}
