import React from 'react'
import './panel.scss'

const PanelHeader = ({
	noPadding,
	defaultStyle,
	border,
	className,
	children,
	...otherProps
}) => {
	const generateClassName = () => {
		let css = 'panel-header'
		if (defaultStyle === false) css = ''
		if (!border) {
			css += ' no-border'
		}
		if (noPadding === true) css += ' no-padding'
		if (className != null) css += ` ${className}`
		return css
	}
	return (
		<div className={generateClassName()} {...otherProps}>
			{children}
		</div>
	)
}

export default PanelHeader

PanelHeader.defaultProps = {
	border: true,
}
