import React from 'react'
import './scrollbars.scss'

export default function Scrollbars({ children, className, ...otherProps }) {
	return (
		<div className={`scrollbars ${className ? className : ''}`} {...otherProps}>
			{children}
		</div>
	)
}
