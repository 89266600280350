import React from 'react'
import './table.scss'

export default function Table({
	children,
	className,
	data,
	columns,
	rows,
	defaultStyle,
	striped,
	highlight,
	handleRowClick,
}) {
	const generateClassName = () => {
		let css = 'basic-table'
		if (defaultStyle) css += ' default'
		if (striped) css += ' -striped'
		if (highlight) css += ' -highlight'
		if (className) css += ` ${className}`
		return css
	}

	if (rows && data.length === rows.length)
		data.forEach((set, i) => set.unshift(rows[i]))

	if (data)
		return (
			<table className={generateClassName()}>
				{columns ? (
					<thead>
						<tr>
							{rows ? <th></th> : null}
							{columns.map((column, i) => (
								<th key={`col-${i}`}>{column}</th>
							))}
						</tr>
					</thead>
				) : null}
				<tbody>
					{rows
						? data.map((set, index) => (
								<tr key={`row-${index}`} onClick={handleRowClick}>
									{set.map((value, i) =>
										i === 0 ? (
											<th key={`row-${i}-cell-${i}`}>{value}</th>
										) : (
											<td key={`row-${i}-cell-${i}`}>{value}</td>
										)
									)}
								</tr>
						  ))
						: data.map((set, index) => (
								<tr key={`row-${index}`} onClick={handleRowClick}>
									{set.map((value, i) => (
										<td key={`row-${i}-cell-${i}`}>{value}</td>
									))}
								</tr>
						  ))}
				</tbody>
			</table>
		)

	return <table className={generateClassName()}>{children}</table>
}

Table.defaultProps = {
	defaultStyle: true,
	striped: true,
	highlight: true,
}
