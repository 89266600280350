import React from 'react'
import {
	ahfaLogo,
	adfaLogo,
	mhdcLogo,
	mshcLogo,
	nchfaLogo,
} from '../assets/images/hfa-logos/_hfa_logos'

const enterpriseObject = {
	'ahfa.quantalytix.com': {
		image: (
			<img
				className='logo'
				src={ahfaLogo}
				alt='AHFA Logo'
				style={{ margin: 'auto' }}
			/>
		),
	},
	'scorecard.ahfa.com': {
		image: (
			<img
				className='logo'
				src={ahfaLogo}
				alt='AHFA Logo'
				style={{ margin: 'auto' }}
			/>
		),
	},
	'adfa.quantalytix.com': {
		image: (
			<img
				className='logo'
				src={adfaLogo}
				alt='ADFA Logo'
				height='50px'
				style={{ margin: 'auto' }}
			/>
		),
	},
	'mhdc.quantalytix.com': {
		image: (
			<img
				className='logo'
				src={mhdcLogo}
				alt='MHDC Logo'
				style={{ margin: 'auto' }}
			/>
		),
	},
	'mshc.quantalytix.com': {
		image: (
			<img
				className='logo'
				src={mshcLogo}
				alt='MHC Logo'
				height='100px'
				style={{ margin: 'auto' }}
			/>
		),
	},
	'nchfa.quantalytix.com': {
		image: (
			<img
				className='logo'
				src={nchfaLogo}
				alt='NCHFA Logo'
				style={{ margin: 'auto', padding: '10px' }}
			/>
		),
	},
}

export default enterpriseObject
