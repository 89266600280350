import React from 'react'
import './draggable-detail.scss'

export default function DraggableDetail() {
	const rows = 13

	const insertRows = () => {
		let rowCounter = rows
		let dots = []
		while (rowCounter > 0) {
			dots.push(<div key={rowCounter} className='dot'></div>)
			rowCounter--
		}
		return dots
	}

	return (
		<div className='draggable-detail'>
			<div className='col'>{insertRows()}</div>
			<div className='col'>{insertRows()}</div>
			<div className='col'>{insertRows()}</div>
		</div>
	)
}
