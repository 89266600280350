import React, { useRef, useEffect } from 'react'
import './textinput.scss'

const TextInput = ({ className, type, autoFocus, required, ...otherProps }) => {
	const inputEl = useRef(null)

	useEffect(() => {
		if (autoFocus && inputEl.current) {
			inputEl.current.focus()
		}
	})

	return (
		<div className='text-input-container'>
			<input
				className={`text-input ${className}`}
				type={type}
				ref={inputEl}
				required={required}
				{...otherProps}
			></input>
			{required ? <span className='required-indicator'></span> : null}
		</div>
	)
}

TextInput.defaultProps = {
	type: 'text',
	className: '',
}

export default TextInput
