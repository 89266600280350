import React from 'react'
import { Button } from '../exports'
import './navigation-button.scss'

export default function NavigationButton({
	className,
	rounded,
	direction,
	text,
	color,
	...otherProps
}) {
	const generateClassName = () => {
		let css = 'navigation-btn'
		if (className) {
			css += ` ${className}`
		}
		if (rounded) {
			css += ' rounded'
		}
		return css
	}

	return (
		<Button color={color} className={generateClassName()} {...otherProps}>
			<div className='btn-content'>
				<div className={'arrow ' + direction}>
					<i className='icon-arrow-up2' />
				</div>
				{text ? <span className='btn-text'>{text}</span> : null}
			</div>
		</Button>
	)
}

NavigationButton.defaultProps = {
	direction: 'back',
}
